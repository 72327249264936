import { Project } from "entities/Project";
import { ProjectType } from "entities/ProjectType";
import { ECollections as coll, FirestoreService } from "../firebase";

interface PROJECT_FILTER {
  email?: string;
}

export class ProjectRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public async get(id: string): Promise<Project> {
    const result = await this.firestore.getDocumentById(coll.PROJECT, id);
    return Project.fromJson(result);
  }

  public async getAll(): Promise<Project[]> {
    const projects = await this.firestore.getAllDocuments(coll.PROJECT);
    return projects.map((project) =>
      Project.fromJson(project.data, project.id),
    );
  }

  public async find(filter: PROJECT_FILTER): Promise<Project[]> {
    let projects: Project[] = [];
    if (filter.email) {
      const results = await this.firestore.findDocumentByQuery(
        coll.PROJECT,
        "assigned_to_users",
        "array-contains",
        filter?.email?.split("@")[0],
      );

      projects = results.map<Project>((result) =>
        Project.fromJson(result.data, result.id),
      );
    }
    return projects;
  }

  public async create(project: Project): Promise<string> {
    project.updatedAt = new Date();
    project.createdAt = new Date();
    const result = await this.firestore.setDocument(
      coll.PROJECT,
      project.toJson(),
    );
    return result.id;
  }

  public async update(id: string, projectData: Project) {
    projectData.updatedAt = new Date();
    const result = await this.firestore.updateDocument(
      coll.PROJECT,
      projectData.toJson(),
      id,
    );
    return { ...result, id };
  }

  public async getAllTypes(): Promise<ProjectType[]> {
    const projectTypes = await this.firestore.getAllDocuments(
      coll.PROJECT_TYPE,
    );
    return projectTypes.map((type) => ProjectType.fromJson(type.data, type.id));
  }
}
