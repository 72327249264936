import { createStore } from "effector";

import * as actions from "./actions";

import { IUseAuth, IUserAuthStore } from "interfaces/IAuth";

export const store = createStore<IUseAuth>(
  {
    isLoading: false,
    hasError: false,
    data: {
      id: localStorage.getItem("id") || "",
      firstName: localStorage.getItem("firstName") || "",
      lastName: localStorage.getItem("lastName") || "",
      email: localStorage.getItem("email") || "",
      accessToken: localStorage.getItem("accessToken") || "",
      photoPath: localStorage.getItem("photoPath") || "",
      admissionDate: new Date(localStorage.getItem("admissionDate") || ""),
      isActive: localStorage.getItem("isActive") ? true : false,
      isLogged: localStorage.getItem("isLogged") ? true : false || false,
      manager: (() => {
        const roleFromLocalStorage = localStorage.getItem("manager");
        if (roleFromLocalStorage) {
          return JSON.parse(roleFromLocalStorage);
        } else {
          return "";
        }
      })(),

      sector: (() => {
        const roleFromLocalStorage = localStorage.getItem("sector");

        if (roleFromLocalStorage) {
          return JSON.parse(roleFromLocalStorage);
        } else {
          return "";
        }
      })(),

      role: (() => {
        const roleFromLocalStorage = localStorage?.getItem("role");
        if (roleFromLocalStorage) {
          return JSON.parse(roleFromLocalStorage);
        } else {
          return "";
        }
      })(),
    },
  },
  {
    name: "auth",
  },
);

store.on(actions.handleSignIn, (state) => ({
  ...state,
  isLoading: true,
  hasError: false,
}));

store.on(actions.handleSignIn.doneData, (state, data) => ({
  ...state,
  data,
  isLoading: false,
  hasError: false,
}));

store.on(actions.handleSignIn.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true,
}));

store.on(actions.handleLogout, (state) => {
  localStorage.removeItem("id");
  localStorage.removeItem("email");
  localStorage.removeItem("lastName");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("photoPath");
  localStorage.removeItem("admissionDate");
  localStorage.removeItem("isActive");
  localStorage.removeItem("sector");
  localStorage.removeItem("manager");
  localStorage.removeItem("isLogged");
  localStorage.removeItem("role");
  localStorage.removeItem("userFilter");
  localStorage.removeItem("clientFilter");
  localStorage.removeItem("taskFilter");
  localStorage.removeItem("projectFilter");

  window.location.reload();

  return {
    ...state,
    data: {
      isLogged: false,
    } as IUserAuthStore,
  };
});
