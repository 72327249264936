import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import Dropdown from "components/Form/Select";

import HeaderInside from "layouts/HeaderInside";
import { CardProject } from "./Components/projectCardToList";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../modules/auth/hooks";
import { ProjectService } from "services/ProjectService";
import { IProject } from "interfaces/IProject";
import { useHistory } from "react-router-dom";
import { checkPermissoion } from "helpers/checkPermission";

import ModalWrapper from "components/Modal";
import { ModalConfirmDelete } from "../../components/Modal/ModalConfirmDelete/confirmDeleteModal";
import { CustomerService } from "services/CustomerService";
import { ICustomer } from "interfaces/ICustomer";
import { sortObjects } from "helpers/utils";
import { useFilters } from "modules/filters/hooks";
import InputWithLabel from "components/Form/input/InputWithLabel";

export const ListProjects: React.FC = () => {
  const { data: searchFilters, handleSetPayload } = useFilters();

  const projectService = new ProjectService();
  const customerService = new CustomerService();

  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectsFiltered, setProjectsFiltered] = useState<IProject[]>([]);
  const [openModalState, setOpenModalChangeProjectState] = useState(false);

  const [loadingProjects, setLoadingProjets] = useState(false);
  const [projectSelected, setProjectsSelected] = useState({} as IProject);
  const [customers, setClients] = useState<ICustomer[]>([]);
  const toast = useToast();
  const [isFilteredByClientName, setIsFilteredByClientName] = useState("");
  const [isFilteredByisActive, setIsFilteredByIsActive] = useState<
    boolean | undefined
  >(false);
  const history = useHistory();
  const { data } = useAuth();

  const applyFilters = (
    filters: {
      customerId?: string;
      isActive?: boolean;
      searchBar?: string;
    },
    projects: IProject[],
  ) => {
    const projectsFiltered = projects?.filter((project) => {
      const customerMatches =
        !filters?.customerId || project?.customer?.id === filters?.customerId;

      const statusMatches =
        filters.isActive === undefined || project.isActive === filters.isActive;

      let nameMatches = true;
      if (filters.searchBar)
        nameMatches = `${project.name}`
          .toLowerCase()
          .includes(filters.searchBar.toLowerCase());

      return customerMatches && statusMatches && nameMatches;
    });

    if (
      !filters.customerId &&
      typeof filters.isActive !== "boolean" &&
      !filters.searchBar
    ) {
      searchFilters.projectFilter = "";
      return setProjectsFiltered(
        projects?.sort((a, b) => a?.name?.localeCompare(b?.name)),
      );
    }

    setProjectsFiltered(
      projectsFiltered?.sort((a, b) => a?.name?.localeCompare(b?.name)),
    );
  };

  const handleFilterByClientName = (name: any) => {
    applyFilters(
      {
        isActive: isFilteredByisActive,
        customerId: name,
        searchBar: searchFilters.projectFilter || "",
      },
      projects,
    );
  };

  const handleFilterByIsActive = (isActive: any) => {
    applyFilters(
      {
        isActive: isActive,
        customerId: isFilteredByClientName,
        searchBar: searchFilters.projectFilter || "",
      },
      projects,
    );
  };

  const handleFilterByIsName = (name: any) => {
    applyFilters(
      {
        isActive: isFilteredByisActive,
        customerId: isFilteredByClientName,
        searchBar: name,
      },
      projects,
    );
  };

  const handleChangeProjectState = useCallback(
    async (project: IProject) => {
      setLoadingProjets(true);
      let response;
      if (project.id)
        response = await projectService.reverseIsActiveProject(
          project,
          project.id,
        );

      toast({
        title: "Dados atualizados!",
        description: "Projeto atualizado",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      if (response?.error) {
        toast({
          title: "Ocorreu um erro!",
          description: "Não foi possivel atualizar o projeto",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      setLoadingProjets(false);
      setProjectsSelected({} as IProject);
      setOpenModalChangeProjectState(false);
      await handleGetProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, openModalState],
  );

  const handleModalToChangeProjectState = useCallback(() => {
    setOpenModalChangeProjectState(!openModalState);
  }, [openModalState]);

  const handleGetProjects = useCallback(async () => {
    setLoadingProjets(true);
    try {
      let projectsResponse: IProject[] = [];
      if (
        checkPermissoion({ permission: ["manager"], userRole: data?.role?.id })
      ) {
        projectsResponse = await projectService.getProjectsByEmail(
          data?.email ?? "",
        );
      }
      if (
        checkPermissoion({ permission: ["admin"], userRole: data?.role?.id })
      ) {
        projectsResponse = await projectService.getAllProjects();
      }
      if (projectsResponse) {
        const customersResponse: ICustomer[] =
          await customerService.getAllCustomers();

        setClients(sortObjects<ICustomer>(customersResponse, "name", "asc"));
        setProjects(projectsResponse);
        applyFilters(
          {
            isActive: true,
            customerId: "",
            searchBar: searchFilters.projectFilter || "",
          },
          projectsResponse,
        );

        setIsFilteredByIsActive(true);
      }
    } catch (err) {
      console.log(err);
      toast({
        title: "Ocorreu um erro!",
        description: "Não foi possivel buscar os dados do projeto",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    setLoadingProjets(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.email, data?.role?.id, toast]);

  useEffect(() => {
    handleGetProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilters(
      {
        isActive: true,
        customerId: "",
        searchBar: searchFilters.projectFilter || "",
      },
      projects,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.projectFilter]);

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>Projetos</Text>

          <Flex align={"center"} justify={"center"} gap={3} cursor={"pointer"}>
            <Text
              fontSize={["sm"]}
              cursor={"pointer"}
              onClick={() => history.push("/projects/create/0")}
            >
              {" "}
              + Adicionar novo
            </Text>
          </Flex>
        </Flex>
      </HeaderInside>

      <Flex p="5" flexDir={"column"}>
        <Flex
          gap="3"
          justify={"space-between"}
          flexDir={["column", "column", "row"]}
        >
          <Flex
            minW={["100%", "100%", "35%"]}
            maxW={["100%", "100%", "35%"]}
            align={"center"}
            justify={"center"}
          >
            <InputWithLabel
              label="Pesquisar"
              value={searchFilters.projectFilter || ""}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  handleFilterByIsName(searchFilters.projectFilter || "");
                }
              }}
              onChange={(e) => {
                handleSetPayload({
                  ...searchFilters,
                  projectFilter: e.target.value,
                });
              }}
            />
          </Flex>

          <Dropdown
            minW={["100%", "100%", "100%"]}
            maxW={["100%", "100%", "100%"]}
            fontSize={["sm"]}
            onChange={(e: any) => {
              setIsFilteredByIsActive(
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
              handleFilterByIsActive(
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
            }}
          >
            <option value="">Filtrar por status </option>
            <option value={"true"} selected>
              Ativados
            </option>
            <option value={"false"}>Desativado</option>
          </Dropdown>

          <Dropdown
            minW={["100%", "100%", "100%"]}
            maxW={["100%", "100%", "100%"]}
            fontSize={["sm"]}
            onChange={(e: any) => {
              setIsFilteredByClientName(e.target.value);
              handleFilterByClientName(e.target.value);
            }}
          >
            <option value="">Filtrar por cliente </option>

            {customers.map((item: ICustomer, key: number | string) => {
              return (
                <option key={key} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Dropdown>
        </Flex>

        <Flex
          maxH="calc(100vh - 350px)"
          // pr="2"
          overflow={"auto"}
          mt="5"
          gap="4"
          flexDir={"column"}
          pr="2"
        >
          {loadingProjects ? (
            <Flex
              align={"center"}
              justify={"center"}
              h={"100%"}
              minH="calc(100vh - 400px)"
              w="100%"
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {projectsFiltered.map((project, index) => {
                return (
                  <CardProject
                    key={index}
                    project={project}
                    handleModalToChangeProjectState={
                      handleModalToChangeProjectState
                    }
                    setProjectsSelected={setProjectsSelected}
                  />
                );
              })}
            </>
          )}

          {/* TODO: Mover todos os modais para um lugar  */}
          <ModalWrapper
            isOpen={openModalState}
            onClose={handleModalToChangeProjectState}
          >
            <ModalConfirmDelete
              handleClose={handleModalToChangeProjectState}
              textModal={`O projeto ${projectSelected.name} será ${projectSelected.isActive ? "Desativado" : "Reativado"}`}
              handleDelete={async () => {
                handleChangeProjectState(projectSelected);
              }}
            />
          </ModalWrapper>
        </Flex>
      </Flex>
    </>
  );
};
