type DocType = "nf" | "pf_id" | "pj_id" | "other";

type UserType = "user" | "customer" | "project";

type IOwner = {
  id: string;
  name: string;
  email: string;
  type: UserType;
};

export class Document {
  id: string;
  uri: string;
  name: string;
  type: DocType;
  owner: IOwner;
  createdAt: Date;
  updatedAt: Date;
  expires_at?: Date;
  description?: string;

  constructor(
    id: string,
    uri: string,
    type: DocType,
    owner: IOwner,
    createdAt: Date,
    updatedAt: Date,
    name: string,
    description?: string,
    expiresAt?: Date,
  ) {
    this.uri = uri;
    this.name = name;
    this.type = type;
    this.owner = owner;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.expires_at = expiresAt;
    this.description = description;
    this.id = id;
  }

  static fromJson(id: string, json: any): Document {
    return new Document(
      id,
      json["uri"],
      json["name"],
      json["type"],
      json["owner"],
      json["created_at"]?.toDate(),
      json["updated_at"]?.toDate(),
      json["expires_at"]?.toDate(),
      json["description"],
    );
  }

  toJson(): any {
    return {
      id: this.id,
      uri: this.uri,
      name: this.name,
      type: this.type,
      owner: this.owner,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      expires_at: this.expires_at,
      description: this.description,
    };
  }
}
