import {
  Flex,
  Box,
  Text,
  Image,
  Avatar,
  useBreakpointValue,
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";

import logo from "assets/logo/LOGO HVAR.svg";

import { useMenu } from "modules/menu/hooks";
import { useAuth } from "modules/auth/hooks";

const Header: React.FC = () => {
  const { handleSetIsVisible, isVisible, title } = useMenu();
  const nameSize = useBreakpointValue({ base: true, md: false, lg: false });
  const { data } = useAuth();

  return (
    <Flex
      bg="white"
      py="3"
      px="2"
      w="100%"
      align={"center"}
      justify={"space-between"}
    >
      <Flex align={"center"} justify={"center"} gap="3">
        <HamburgerIcon
          onClick={() => handleSetIsVisible(!isVisible)}
          cursor={"pointer"}
          height={"25px"}
        />

        <Box height={"25px"} width={"100%"}>
          <Image src={logo} alt="Logo Hvar" height={"25px"} />
        </Box>
      </Flex>

      <Flex>
        <Text as="h1" color="grayLight" fontSize={["sm", "md", "lg"]}>
          {title.split("-")[0]}
        </Text>
      </Flex>

      <Flex align={"center"} justify={"center"} gap="5">
        <Flex align={"center"} justify={"center"}>
          <Avatar
            name={data?.firstName || undefined}
            src={data?.photoPath || undefined}
            size="sm"
            mr="3"
          />
          <Text
            as="span"
            fontSize={["xs", "sm", "md"]}
            textTransform={"capitalize"}
          >
            {nameSize ? (
              <>
                <>{data?.firstName}</>
              </>
            ) : (
              <>
                {data?.firstName} {data?.lastName}
              </>
            )}
          </Text>
        </Flex>

        {/*
         implementar depois
        <Box height={'20px'} pos="relative">
          <Box
            height={'10px'}
            width={'10px'}
            borderRadius="10px"
            pos="absolute"
            right={0}
            top="-2px"
            bg="red"
            color="red"
          />
          <Image src={notification} alt="Logo Hvar" height={'20px'} />
        </Box> */}
      </Flex>
    </Flex>
  );
};

export default Header;
