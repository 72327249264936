import { IFirebaseResponse } from "interfaces/IFirebaseResponse";
import { StorageService } from "../firebase";

import { ICustomer } from "interfaces/ICustomer";
import { CustomerRepository } from "repositories/CustomerRepository";
import { Customer } from "entities/Customer";

export class CustomerService {
  private customerRepository = new CustomerRepository();

  convertToInterface(customer: Customer): ICustomer {
    return <ICustomer>{
      id: customer.id,
      name: customer.name,
      isActive: customer.isActive,
      phoneNumber: customer.phoneNumber,
      email: customer.email,
      cnpj: customer.cnpj,
      segment: customer.segment,
      website: customer.website,
      logoPath: customer.logoPath,
      contact: customer.contact,
      createdAt: customer.createdAt,
      updatedAt: customer.updatedAt,
    };
  }

  convertToEntity(customer: ICustomer): Customer {
    const dateNow = new Date();

    return new Customer(
      customer.name,
      customer.isActive,
      customer.createdAt ? customer.createdAt : dateNow,
      customer.updatedAt ? customer.updatedAt : dateNow,
      customer.phoneNumber,
      customer.email,
      customer.cnpj,
      customer.segment,
      customer.website,
      customer.logoPath,
      customer.contact,
    );
  }

  public async getById(id: string): Promise<ICustomer | undefined> {
    const result = await this.customerRepository.get(id);
    if (result) return this.convertToInterface(result);
  }

  public async getByName(name: string) {
    const result = (await this.customerRepository.find({ name: name }))[0];

    return this.convertToInterface(result);
  }

  public async getAllCustomers(): Promise<ICustomer[]> {
    const customerResult = await this.customerRepository.getAll();
    return customerResult.map((customer) => this.convertToInterface(customer));
  }

  public async postCustomer(customer: ICustomer): Promise<ICustomer> {
    const entitie = this.convertToEntity(customer);

    const customerSaved = await this.customerRepository.create(entitie);
    return customerSaved;
  }

  public async updateCustomer(
    id: string,
    customer: ICustomer,
  ): Promise<ICustomer> {
    const entity = this.convertToEntity(customer);

    const result = await this.customerRepository.update(id, entity);
    return result;
  }

  // TODO: Rewrite, this cannot return IFirebaseResponse
  public async uploadImgCustomer(
    imageData: Blob,
    path: string,
  ): Promise<IFirebaseResponse<any>> {
    const result: IFirebaseResponse<any> = {
      data: null,
      error: null,
    };
    try {
      const storageService: StorageService = new StorageService();

      const customerImg = await storageService.uploadImage(imageData, path);
      result.data = customerImg;
    } catch (e) {
      result.error = e;
    }
    return result;
  }

  // TODO: Rewrite, this cannot return IFirebaseResponse
  public async getAllCustomersImg(id: string): Promise<IFirebaseResponse<any>> {
    const result: IFirebaseResponse<any> = {
      data: null,
      error: null,
    };
    try {
      const storage: StorageService = new StorageService();

      const timeSheetSaved = await storage.getImagesInFolder(id);
      result.data = timeSheetSaved;
    } catch (e) {
      result.error = e;
    }
    return result;
  }

  public async toggleIsActiveProject(
    customer: ICustomer,
    id: string,
  ): Promise<any> {
    const entity = this.convertToEntity({
      ...customer,
      isActive: customer.isActive === true ? !customer.isActive : true,
    });

    const result = await this.customerRepository.update(id, entity);

    return result;
  }
}
