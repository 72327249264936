import { IProject } from "../interfaces/IProject";
import { IProjectTypes } from "interfaces/IProjectTypes";
import { ProjectRepository } from "repositories/ProjectRepository";
import { Project } from "entities/Project";

export class ProjectService {
  private projectRepository = new ProjectRepository();

  // TODO: Convert IProject to Project

  convertToEntity(project: IProject): Project | undefined {
    if (
      (project.currentStep == "Backlog" ||
        project.currentStep == "Discovery & Assessment" ||
        project.currentStep == "Planejamento" ||
        project.currentStep == "Levantamento Técnico" ||
        project.currentStep == "Desenvolvimento" ||
        project.currentStep == "Teste Integrado" ||
        project.currentStep == "Homologação" ||
        project.currentStep == "Go Live" ||
        project.currentStep == "Finalizado" ||
        project.currentStep == "Suporte Técnico" ||
        project.currentStep == "Alocação") &&
      (project.risk == "baixo" ||
        project.risk == "médio" ||
        project.risk == "neutro" ||
        project.risk == "alto" ||
        project.risk == "muito alto")
    ) {
      return new Project(
        project.name,
        project.code,
        project.managers,
        project.currentStep,
        project.billable,
        project.isBlocked,
        project.risk,
        project.type,
        project.isActive,
        project.customer,
        project.description,
        project.successCriteria,
        project.learningSummary,
        project.startedAt,
        project.endedAt,
        project.expectedStart,
        project.expectedEnd,
        project.additionalHours,
        project.soldHours,
        project.outcome,
        project.revenueRecognition,
        project.createdAt,
        project.updatedAt,
        project.assignedToUsers,
      );
    }
  }

  convertToInterface(project: Project): IProject {
    return {
      id: project.id,
      name: project.name,
      description: project.description,
      code: project.code,
      managers: project.managers,
      currentStep: project.currentStep,
      isBlocked: project.isBlocked,
      learningSummary: project.learningSummary,
      successCriteria: project.successCriteria,
      createdAt: project.createdAt,
      updatedAt: project.updatedAt,
      startedAt: project.startedAt,
      endedAt: project.endedAt,
      expectedStart: project.expectedStart,
      expectedEnd: project.expectedEnd,
      billable: project.billable,
      outcome: project.outcome,
      assignedToUsers: project.assignedToUsers,
      risk: project.risk,
      revenueRecognition: project.revenueRecognition,
      soldHours: project.soldHours,
      isActive: project.isActive,
      type: project.type,
      customer: project.customer,
    };
  }

  public async getProjectsByEmail(userEmail: string): Promise<IProject[]> {
    const result = await this.projectRepository.find({ email: userEmail });
    return result.map((projects) => this.convertToInterface(projects));
  }

  public async getAllProjects(): Promise<IProject[]> {
    const result = await this.projectRepository.getAll();
    return result.map((project) => this.convertToInterface(project));
  }

  public async getProjectsById(projectId: string): Promise<IProject> {
    const result = await this.projectRepository.get(projectId);
    return this.convertToInterface(result);
  }

  public async getProjectType(): Promise<IProjectTypes[]> {
    const result = await this.projectRepository.getAllTypes();
    return result.map((type) => {
      return <IProjectTypes>{
        id: type.id,
        name: type.name,
        created_at: type.createdAt,
        description: type.description,
        updated_at: type.updatedAt,
      };
    });
  }

  public async createProject(project: IProject): Promise<string | undefined> {
    const entity = this.convertToEntity(project);

    if (entity) return await this.projectRepository.create(entity);
  }

  public async updateProject(project: IProject, id: string): Promise<any> {
    const entity = this.convertToEntity(project);
    let result;

    if (entity) {
      result = await this.projectRepository.update(id, entity);
    }
    return result;
  }

  public async reverseIsActiveProject(
    project: IProject,
    id: string,
  ): Promise<any> {
    project.isActive = !project.isActive;

    const entity = this.convertToEntity(project);
    let result;

    if (entity) {
      result = await this.projectRepository.update(id, entity);
    }
    return result;
  }
}
