import { createStore } from "effector";

import * as actions from "./actions";

import { IUseFilters } from "interfaces/IFilters";

export const store = createStore<IUseFilters>(
  {
    data: {
      userFilter: localStorage.getItem("userFilter") || "",
      clientFilter: localStorage.getItem("clientFilter") || "",
      taskFilter: localStorage.getItem("taskFilter") || "",
      projectFilter: localStorage.getItem("projectFilter") || "",
    },
  },
  {
    name: "auth",
  },
);

store.on(actions.handleSetPayload, (state, payload) => {
  Object.keys(payload).forEach((key) => {
    localStorage.setItem(key, payload[key]);
  });

  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  };
});
