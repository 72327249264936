import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import Dropdown from "components/Form/Select";
import HeaderInside from "layouts/HeaderInside";
import { ClientCard } from "../components/ClientCard";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { CustomerService } from "services/CustomerService";
import { ICustomer } from "interfaces/ICustomer";
import InputWithLabel from "components/Form/input/InputWithLabel";
import { useFilters } from "modules/filters/hooks";
import ModalWrapper from "components/Modal";
import { ModalConfirmDelete } from "components/Modal/ModalConfirmDelete/confirmDeleteModal";

export const ClientsList: React.FC = () => {
  const { data, handleSetPayload } = useFilters();
  const [openModalCofirmDelete, setOpenModalCofirmDelete] = useState(false);
  const [clientSelected, setClientSelected] = useState({} as ICustomer);
  const options = [
    { label: "Ativado", value: "true" },
    { label: "Desativado", value: "false" },
  ];

  const [isFilteredByisActive, setIsFilteredByIsActive] = useState<
    boolean | undefined
  >(true);
  const history = useHistory();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customersFiltered, setCustomersFiltered] = useState<ICustomer[]>([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const customerService = new CustomerService();

  const handleOpenModalConfirm = (client?: ICustomer) => {
    setClientSelected(client ?? ({} as ICustomer));
    setOpenModalCofirmDelete((prev) => !prev);
  };

  const handleChangeIsActiveClient = useCallback(
    async (customer: ICustomer) => {
      if (!customer.id) return;

      const response = await customerService.toggleIsActiveProject(
        customer,
        customer.id,
      );

      if (!response) {
        toast({
          title: "Ocorreu um erro!",
          description: "Erro ao desativar o cliente!",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Dados atualizados com sucesso!",
          description: "Cliente foi atualizado com sucesso!",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
      handleOpenModalConfirm();
      handleGetCustomers(isFilteredByisActive);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFilteredByisActive, customerService, toast],
  );

  const handleGetCustomers = useCallback(
    async (isFilteredByisActive?: boolean) => {
      setLoading(true);

      const response = (await customerService.getAllCustomers()).sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      setCustomers(response);
      handleFilter(data.clientFilter || "", response);
      handleFilterByIsActive(isFilteredByisActive);
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerService, data.clientFilter],
  );

  const handleFilter = useCallback(
    (searchValue: string, customers: ICustomer[]) => {
      const lowerCaseSearchValue = searchValue.toLowerCase();
      const filteredCustomers = customers.filter((item) =>
        lowerCaseSearchValue
          ? item.name.toLowerCase().includes(lowerCaseSearchValue)
          : isFilteredByisActive === false
            ? !item.isActive
            : item.isActive,
      );
      setCustomersFiltered(filteredCustomers || []);
    },
    [isFilteredByisActive],
  );

  const handleFilterByIsActive = useCallback(
    (isActive: boolean | undefined) => {
      if (isActive !== undefined) {
        setCustomersFiltered(
          customers.filter((item) => item.isActive === isActive),
        );
      } else {
        setCustomersFiltered(customers);
      }
    },
    [customers],
  );

  useEffect(() => {
    handleGetCustomers(isFilteredByisActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFilter(data.clientFilter || "", customers);
  }, [data.clientFilter, customers, handleFilter]);

  //

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>Clientes</Text>
          <Text
            fontSize={["sm"]}
            cursor={"pointer"}
            onClick={() => history.push("/clients/create")}
          >
            + Adicionar novo
          </Text>
        </Flex>
      </HeaderInside>
      <Flex p="5" flexDir={"column"}>
        <Flex
          align={"center"}
          justify={"space-between"}
          gap="3"
          flexDir={["column", "column", "row"]}
        >
          <Flex
            w="100%"
            align={"center"}
            justify={"center"}
            gap={5}
            borderColor={"orange2"}
          >
            <InputWithLabel
              label="Pesquisar"
              value={data.clientFilter || ""}
              onChange={(e) =>
                handleSetPayload({ ...data, clientFilter: e.target.value })
              }
            />

            <Dropdown
              fontSize={["sm"]}
              value={isFilteredByisActive?.toString() || ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const value =
                  e.target.value === "false"
                    ? false
                    : e.target.value === ""
                      ? undefined
                      : Boolean(e.target.value);
                setIsFilteredByIsActive(value);
                handleFilterByIsActive(value);
              }}
            >
              {options.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Dropdown>
          </Flex>
        </Flex>

        <Flex
          flexDir={"column"}
          maxH="calc(100vh - 400px)"
          pr="2"
          overflow={"auto"}
          mt="5"
          gap="3"
        >
          {loading ? (
            <Flex
              minH="calc(100vh - 400px)"
              w="100%"
              align={"center"}
              justify={"center"}
            >
              <Spinner />
            </Flex>
          ) : (
            customersFiltered.map((item, index) => (
              <ClientCard
                key={item.id || index}
                client={item}
                handleOpenModalConfirm={handleOpenModalConfirm}
              />
            ))
          )}
        </Flex>
      </Flex>

      <>
        <ModalWrapper
          isOpen={openModalCofirmDelete}
          onClose={handleOpenModalConfirm}
        >
          <ModalConfirmDelete
            handleClose={handleOpenModalConfirm}
            textModal={`O cliente: ${clientSelected.name} será ${clientSelected.isActive ? "desativado" : "ativado"} `}
            handleDelete={async () => {
              await handleChangeIsActiveClient(clientSelected);
            }}
          />
        </ModalWrapper>
      </>
    </>
  );
};
