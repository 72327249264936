export enum EProjectSteps {
  BACKLOG = "Backlog",
  DISCOVER_ASSESSMENT = "Discovery & Assessment",
  PLANNING = "Planejamento",
  TECHNICAL_SURVEY = "Levantamento Técnico",
  DEVELOPING = "Desenvolvimento",
  INTEGRATION_TEST = "Teste Integrado",
  HOMOLOGING = "Homologação",
  GO_LIVE = "Go Live",
  FINISHED = "Finalizado",
  TECHNICAL_SUPPORT = "Suporte Técnico",
  ALLOCATION = "Alocação",
}

export class Project {
  id?: string;
  name: string;
  description?: string;
  code: string;
  managers: {
    [key: string]: { name: string; role: "account_owner" | "account" };
  } = {};
  currentStep:
    | "Backlog"
    | "Discovery & Assessment"
    | "Planejamento"
    | "Levantamento Técnico"
    | "Desenvolvimento"
    | "Teste Integrado"
    | "Homologação"
    | "Go Live"
    | "Finalizado"
    | "Suporte Técnico"
    | "Alocação";
  isBlocked: boolean;
  successCriteria?: string;
  learningSummary?: string;
  startedAt?: Date;
  endedAt?: Date;
  expectedStart?: Date;
  expectedEnd?: Date;
  additionalHours?: number;
  soldHours?: number;
  billable: boolean;
  outcome?: string;
  risk: "baixo" | "médio" | "neutro" | "alto" | "muito alto" = "neutro";
  revenueRecognition?: string;
  isActive = true;
  type: { id: string; name: string };
  customer: { id: string; name: string; email: string; segment: string };
  createdAt: Date;
  updatedAt: Date;
  assignedToUsers?: string[];

  constructor(
    name: string,
    code: string,
    managers: {
      [key: string]: { name: string; role: "account_owner" | "account" };
    } = {},
    currentStep:
      | "Backlog"
      | "Discovery & Assessment"
      | "Planejamento"
      | "Levantamento Técnico"
      | "Desenvolvimento"
      | "Teste Integrado"
      | "Homologação"
      | "Go Live"
      | "Finalizado"
      | "Suporte Técnico"
      | "Alocação" = "Backlog",
    billable: boolean,
    isBlocked = false,
    risk: "baixo" | "médio" | "neutro" | "alto" | "muito alto" = "neutro",
    type: { id: string; name: string },
    isActive = true,
    customer: { id: string; name: string; email: string; segment: string },
    description?: string,
    successCriteria?: string,
    learningSummary?: string,
    startedAt?: Date,
    endedAt?: Date,
    expectedStart?: Date,
    expectedEnd?: Date,
    additionalHours?: number,
    soldHours?: number,
    outcome?: string,
    revenueRecognition?: string,
    createdAt?: Date,
    updatedAt?: Date,
    assignedToUsers?: string[],
    id?: string,
  ) {
    this.name = name;
    this.code = code;
    this.managers = managers;
    this.currentStep = currentStep;
    this.billable = billable;
    this.isBlocked = isBlocked;
    this.risk = risk;
    this.type = type;
    this.isActive = isActive;
    this.customer = customer;
    this.description = description;
    this.successCriteria = successCriteria;
    this.learningSummary = learningSummary;
    this.startedAt = startedAt;
    this.endedAt = endedAt;
    this.expectedStart = expectedStart;
    this.expectedEnd = expectedEnd;
    this.additionalHours = additionalHours;
    this.soldHours = soldHours;
    this.outcome = outcome;
    this.revenueRecognition = revenueRecognition;
    this.createdAt = createdAt || new Date();
    this.updatedAt = updatedAt || new Date();
    this.assignedToUsers = assignedToUsers;
    this.id = id;
  }

  static fromJson(json: any, id?: string): Project {
    return new Project(
      json["name"],
      json["code"],
      json["managers"],
      json["current_step"],
      json["billable"],
      json["is_blocked"],
      json["risk"],
      json["type"],
      json["is_active"],
      json["customer"],
      json["description"],
      json["success_criteria"],
      json["learning_summary"],
      json["started_at"]?.toDate(),
      json["ended_at"]?.toDate(),
      json["expected_start"]?.toDate(),
      json["expected_end"]?.toDate(),
      json["additional_hours"],
      json["sold_hours"],
      json["outcome"],
      json["revenue_recognition"],
      json["created_at"]?.toDate(),
      json["updated_at"]?.toDate(),
      json["assigned_to_users"],
      id,
    );
  }

  toJson(): any {
    const fields = {
      name: this.name,
      description: this.description,
      code: this.code,
      managers: this.managers,
      current_step: this.currentStep,
      is_blocked: this.isBlocked,
      success_criteria: this.successCriteria,
      learning_summary: this.learningSummary,
      started_at: this.startedAt,
      ended_at: this.endedAt,
      expected_start: this.expectedStart,
      expected_end: this.expectedEnd,
      additional_hours: this.additionalHours,
      sold_hours: this.soldHours,
      billable: this.billable,
      outcome: this.outcome,
      risk: this.risk,
      revenue_recognition: this.revenueRecognition,
      is_active: this.isActive,
      type: this.type,
      customer: this.customer,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      assigned_to_users: this.assignedToUsers,
    };

    const newObj = {};
    for (const key in fields) {
      if (fields[key] !== undefined) {
        newObj[key] = fields[key];
      }
    }

    return newObj;
  }
}
