import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { ModalAddTask } from "../Components/taskDataModal";
import { useCallback, useEffect, useState } from "react";
import { CardTask } from "../Components/taskCardToList";
import { IProjectPageProps } from "interfaces/IProject";
import { TaskService } from "services/TaskService";
import { useHistory } from "react-router-dom";
import ModalWrapper from "components/Modal";
import { ITask } from "interfaces/ITask";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";

export const Tasks: React.FC<IProjectPageProps> = ({ projectId, project }) => {
  const [modalTaskData, setModalTaskData] = useState(false);
  const [modalToDeleteTask, setModalToDeleteTask] = useState(false);

  const [isLoadingTasks, setIsLoadingTasks] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [usersInProject, setUsersInProject] = useState<IUser[]>([]);
  const [selectedTask, setSelectedTask] = useState<ITask>({
    id: "",
    name: "",
    manager: {
      id: "",
      name: "",
      email: "",
    },
    description: "",
    expectedStart: undefined,
    expectedEnd: undefined,
    expectedHours: undefined,
    assignedToUsers: [],
    isActive: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const taskService = new TaskService();
  const userService = new UserService();

  const handleSaveTaskData = useCallback(
    async (task: ITask) => {
      setIsLoadingTasks(true);
      let response;
      if (
        task.name == undefined ||
        task.name == "" ||
        task.manager == undefined ||
        task.manager.id == ""
      ) {
        toast({
          title: "Ocorreu um erro.",
          description: "Alguns campos estão vazios",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingTasks(false);
        return;
      }

      if (task.id) response = await taskService.updateTask(projectId, task);
      else response = await taskService.createTask(projectId, task);
      if (response?.error) {
        toast({
          title: "Ocorreu um erro.",
          description: "Erro ao salvar a task",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Dados atualizados com sucesso.",
          description: "Task foi salva com sucesso!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // TODO: Não buscar novamente, alterar o valor na lista
        handleGetProjectTasks();
        setIsLoadingTasks(false);
        setModalTaskData(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks, history, projectId, toast],
  );

  const handleDeleteTask = useCallback(
    async (task) => {
      if (task) {
        setModalToDeleteTask(true);
        await taskService.toggleIsActiveTask(projectId, task, task.id);
        handleGetProjectTasks();
        setModalToDeleteTask(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, selectedTask, toast, modalToDeleteTask],
  );

  const handleSelectTask = useCallback((task: ITask | undefined) => {
    if (task == undefined) {
      setSelectedTask({
        id: "",
        name: "",
        manager: {
          id: "",
          name: "",
          email: "",
        },
        assignedToUsers: [],
        isActive: true,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } else {
      setSelectedTask(task);
    }
    setModalTaskData(true);
  }, []);

  const handleGetUsersInProject = useCallback(async (emailUsers?: string[]) => {
    const usersRequested: IUser[] = [];
    if (emailUsers) {
      emailUsers.map(async (u) => {
        // TODO: Fazer uma request só ja buscando todos os emails necessarios
        const userFinded = await userService.getUserByEmail(
          u + "@hvarconsulting.com.br",
        );
        if (userFinded) {
          usersRequested.push(userFinded);
        }
      });
    }
    setUsersInProject(usersRequested);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProjectTasks = useCallback(async () => {
    if (projectId) {
      setIsLoadingTasks(true);
      const response = await taskService.getProjectTasks(projectId);

      if (response.length > 0) {
        setTasks(response);
        setIsLoadingTasks(false);
      }
      setIsLoadingTasks(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    handleGetProjectTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetUsersInProject(project.assignedToUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <>
      <Flex p="5" flexDir={"column"}>
        <Flex gap="3" justify={"space-between"} align={"center"}>
          <Flex align={"center"} justify={"center"} gap="2">
            <Text color="green1" fontSize={["md", "lg"]}>
              Tasks do projeto
            </Text>
          </Flex>
          <Text
            fontSize={["xs", "sm"]}
            cursor={"pointer"}
            _hover={{
              opacity: 0.7,
            }}
            onClick={() => {
              handleSelectTask(undefined);
            }}
          >
            Adicionar nova +
          </Text>
        </Flex>

        <Flex
          maxH="calc(100vh - 430px)"
          overflow={"auto"}
          mt="5"
          gap="4"
          flexDir={"column"}
          pr="2"
        >
          {isLoadingTasks ? (
            <Flex
              align={"center"}
              justify={"center"}
              h={"100%"}
              minH="calc(100vh - 500px)"
              w="100%"
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {tasks.map((task, index) => {
                return (
                  <CardTask
                    key={index}
                    task={task}
                    handleSelectTask={() => handleSelectTask(task)}
                    handleDeleteTask={() => handleDeleteTask(task)}
                  />
                );
              })}
            </>
          )}

          <ModalWrapper
            isOpen={modalTaskData}
            onClose={() => {
              setModalTaskData(!modalTaskData);
            }}
            isShowCloseButton={true}
            overflow="auto"
            minW={["200px", "500px", "800px"]}
            h="650px"
          >
            <ModalAddTask
              task={selectedTask}
              isLoadingTasks={isLoadingTasks}
              usersInProject={usersInProject}
              setSelectedTask={setSelectedTask}
              handleSaveTaskData={handleSaveTaskData}
              setModalTaskData={setModalTaskData}
            />
          </ModalWrapper>
        </Flex>
      </Flex>
    </>
  );
};
