import { initializeApp } from "firebase/app";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  UserCredential,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import {
  collection,
  query,
  where,
  getDocs,
  WhereFilterOp,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  connectFirestoreEmulator,
  deleteDoc,
  initializeFirestore,
} from "firebase/firestore";

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";

const projectId = process.env.REACT_APP_PROJECT_ID;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: projectId + ".firebaseapp.com",
  projectId: projectId,
  storageBucket: "gs://" + projectId + ".appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
const auth = getAuth(app);

if (process.env.REACT_APP_NODE_ENV == "local") {
  console.log("ENVIROMENT:" + process.env.REACT_APP_NODE_ENV);
  connectFirestoreEmulator(db, "127.0.0.1", 8080);

  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export enum ECollections {
  USER = "users",
  USER_ROLE = "user_roles",
  SECTORS = "sectors",
  TIMESHEET = "timesheets",
  STATUS_TYPE = "status_types",
  DOCUMENTS = "documents",
  CUSTOMER = "customers",
  PROJECT = "projects",
  PROJECT_TYPE = "project_types",
  ADDITIONAL_TIME_REQUEST = "additional_time_requests",
  TASK = "tasks",
  LOG = "logs",
}

export interface IGetReport {
  year: number;
  month: number;
}

// 64 6F 6E 27 74 20 70 61 6E 69 63

export class OAuthService {
  provider = new GoogleAuthProvider();

  // TODO: Verificar retorno
  public async singIn(): Promise<any> {
    return await signInWithPopup(auth, this.provider);
  }

  public async createUserInOAuth(email: string): Promise<UserCredential> {
    return await createUserWithEmailAndPassword(
      auth,
      email,
      crypto.randomUUID(),
    );
  }
}

export class FirestoreService {
  public async findAllCollection(collectionPath: string) {
    const tasksRef = collection(db, collectionPath);
    const subCollections: any[] = [];
    try {
      const snapshot = await getDocs(tasksRef);

      snapshot.forEach((doc) => {
        subCollections.push({ ...doc.data(), id: doc.id });
      });

      return subCollections;
    } catch (error) {
      throw error;
    }
  }
  // TODO: rewirte to not get is_active = false
  public async findDocumentByChunks(
    collectionName: string,
    key: string,
    operator: WhereFilterOp,
    values: any[],
  ): Promise<any[]> {
    try {
      const collectionRef = collection(db, collectionName);
      const dataResult: any[] = [];
      const chunks: any[] = [];
      for (let i = 0; i < values.length, (i += 30); i += 30) {
        chunks.push(values.slice(i, i + 30));
      }
      const q = query(collectionRef, where(key, operator, values));

      const docs = await getDocs(q);
      docs.forEach((doc) => {
        dataResult.push({ id: doc.id, data: doc.data() });
      });
      return dataResult;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  // TODO: rewirte to not get is_active = false
  public async findDocumentByQuery(
    collectionName: string,
    field: string,
    operator: WhereFilterOp,
    value: string | boolean | string[],
  ): Promise<any[]> {
    try {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where(field, operator, value));

      const dataResult: any[] = [];
      const docSnap = await getDocs(q);
      docSnap.forEach((doc) => {
        dataResult.push({ id: doc.id, data: doc.data() });
      });
      return dataResult;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  // TODO: rewirte to not get is_active = false
  public async findSubcollection(collectionPath: string) {
    const tasksRef = collection(db, collectionPath);
    const subCollections: any[] = [];
    try {
      const snapshot = await getDocs(tasksRef);

      snapshot.forEach((doc) => {
        subCollections.push({ id: doc.id, ...doc.data() });
      });

      return subCollections;
    } catch (error) {
      throw error;
    }
  }

  public async getAllDocumentsPagination(
    collectionPath: string,
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) {
    const tasksRef = collection(db, collectionPath);
    const subCollections: any[] = [];

    try {
      let querySnapshot;

      if (searchTerm) {
        const q = query(
          tasksRef,
          where("name", ">=", searchTerm),
          where("name", "<=", searchTerm + "\uf8ff"),
        );

        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(tasksRef);
      }

      const totalDocuments = querySnapshot.size;

      // Calcular o número total de páginas disponíveis
      const totalPages = Math.ceil(totalDocuments / pageSize);

      // Verificar se a página solicitada está dentro dos limites
      if (page < 1 || page > totalPages) {
        throw new Error("Página solicitada fora dos limites");
      }

      // Calcular o índice inicial e final baseado na página e no tamanho da página
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, totalDocuments);

      let index = 0;
      querySnapshot.forEach((doc) => {
        if (index >= startIndex && index < endIndex) {
          subCollections.push({ id: doc.id, ...doc.data() } as any);
        }
        index++;
      });

      return {
        data: subCollections,
        totalPages: totalPages,
      };
    } catch (error) {
      throw error;
    }
  }

  // TODO: rewirte to not get is_active = false
  public async getDocumentById(collectionName: string, docId: string) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  // TODO: rewirte to not get is_active = false
  public async getAllDocuments(collectionName: string) {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const documents = querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      return documents;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async setDocument(
    collectionPath: string,
    data: any,
    documentId?: string,
  ): Promise<any> {
    try {
      const result =
        documentId != undefined
          ? await setDoc(doc(db, collectionPath, documentId), data)
          : await addDoc(collection(db, collectionPath), data);
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async updateDocument(
    collectionPath: string,
    data: any,
    documentId: string,
  ): Promise<any> {
    try {
      await updateDoc(doc(db, collectionPath, documentId), data, {
        mergeFields: true,
      });

      return data;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async updateSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    subdocumentId: string,
    data: any,
  ): Promise<any> {
    try {
      const documentRef = doc(
        db,
        collectionPath,
        documentId,
        subcollectionPath,
        subdocumentId,
      );
      const response = await updateDoc(documentRef, data);
      return response;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async createSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    data: any,
  ): Promise<any> {
    try {
      const documentRef = doc(db, collectionPath, documentId);

      const subcollectionRef = collection(documentRef, subcollectionPath);
      const newDocumentRef = await addDoc(subcollectionRef, data);

      return newDocumentRef;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async deleteDocument(
    collectionPath: string,
    documentId: string,
  ): Promise<void> {
    try {
      const documentRef = doc(collection(db, collectionPath), documentId);
      await deleteDoc(documentRef);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async deleteDocumentVirtually(
    collectionPath: string,
    documentId: string,
  ): Promise<any> {
    try {
      const disabeDoc = { is_active: false };
      const result = updateDoc(doc(db, collectionPath, documentId), disabeDoc);
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }
}

export class StorageService {
  public async deleteDocInBucket(storagePath: string): Promise<void> {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, storagePath);

      await deleteObject(imageRef);
    } catch (error: any) {
      throw new Error(`Erro ao remover a imagem: ${error.message}`);
    }
  }

  public async uploadImage(
    imageData: Blob,
    storagePath: string,
  ): Promise<string> {
    try {
      const storage = getStorage();
      const hash = this.generateUniqueName();

      const imageRef = ref(storage, `${storagePath}/${hash}`);

      await uploadBytes(imageRef, imageData);

      const downloadURL = await getDownloadURL(imageRef);

      return downloadURL;
    } catch (error: any) {
      throw new Error(`Erro ao enviar a imagem: ${error.message}`);
    }
  }

  public async getImagesInFolder(storagePath: string) {
    const storage = getStorage(app);
    const bucketRef = ref(storage, `/${storagePath}`);

    try {
      const files = await listAll(bucketRef);
      const urlsImagens = await Promise.all(
        files.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return url;
        }),
      );

      return urlsImagens[urlsImagens.length - 1];
    } catch (error) {
      console.error("Erro ao listar arquivos na pasta do bucket:", error);
      // TODO: Trate o erro adequadamente
      throw error;
    }
  }

  public generateUniqueName(): string {
    // Gere um nome de arquivo único usando timestamp e um número aleatório
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10000);
    return `${timestamp}_${randomNumber}`;
  }
}

export class FunctionService {
  public async getReport({ year, month }: IGetReport) {
    const functions = getFunctions();
    const addMessage = httpsCallable(functions, "onReportRequest");
    let response;
    await addMessage({ year: Number(year), month: Number(month) })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        response = data;
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;

        response = `${code} ${message} ${details}`;
        // ...
      });

    return response;
  }

  public async checkIfExistsReport({ year, month }: IGetReport) {
    const storage = getStorage(app);
    const bucketRef = ref(
      storage,
      `gs://hvar-connect.appspot.com/reports/${year}/${month < 10 ? `0${month}` : month}`,
    );

    try {
      const files = await listAll(bucketRef);

      return files.items;
    } catch (error) {
      console.error("Erro ao listar arquivos na pasta do bucket:", error);
      // Trate o erro adequadamente
      throw error;
    }
  }
}
