import { Sector } from "entities/Sector";

import { FirestoreService, ECollections as coll } from "../firebase";

export class SectorRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public async getAll(): Promise<Sector[]> {
    const sectors = await this.firestore.findAllCollection(coll.SECTORS);

    return sectors;
  }
}
