import { Customer } from "entities/Customer";
import { ECollections as coll, FirestoreService } from "../firebase";

interface CUSTOMER_FILTER {
  id?: string;
  name?: string;
}

export class CustomerRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public async find(filter: CUSTOMER_FILTER): Promise<Customer> {
    let customerResult;

    if (filter.name) {
      const customerDoc = await this.firestore.findDocumentByQuery(
        coll.CUSTOMER,
        "name",
        "==",
        filter.name,
      );
      customerResult = customerDoc.map((customer) =>
        Customer.fromJson(customer.data, customer.id),
      );
    }

    if (filter.id) {
      const customerDoc = await this.firestore.getDocumentById(
        coll.CUSTOMER,
        filter.id,
      );
      customerResult = Customer.fromJson(customerDoc, filter.id);
    }

    return customerResult;
  }

  public async get(id: string): Promise<Customer | undefined> {
    const result = await this.firestore.getDocumentById(coll.CUSTOMER, id);
    if (result) return Customer.fromJson(result, id);
  }

  public async create(customer: Customer): Promise<Customer> {
    customer.updatedAt = new Date();
    customer.createdAt = new Date();

    const result = this.firestore.setDocument(coll.CUSTOMER, customer.toJson());
    return result;
  }

  public async update(id: string, customerData: Customer): Promise<Customer> {
    const firebase = new FirestoreService();
    customerData.updatedAt = new Date();

    const customerDocument = await firebase.updateDocument(
      coll.CUSTOMER,
      customerData.toJson(),
      id,
    );

    return customerDocument;
  }

  public async getAll(): Promise<Customer[]> {
    const customers = await this.firestore.getAllDocuments(coll.CUSTOMER);

    return customers.map((customer) =>
      Customer.fromJson(customer.data, customer.id),
    );
  }
}
