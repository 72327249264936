import { OAuthService } from "../firebase";
import { UserService } from "services/UserService";

const signIn = async (): Promise<any> => {
  const userService = new UserService();

  const auth = new OAuthService();
  const result = await auth.singIn();

  const email = result.user.email;
  const id = result.user.uid;
  const photoPath = result.user.photoURL;
  const accessToken = result.user.accessToken;

  const user = await userService.getUserById(id);

  if (user) {
    if (user.photoPath !== photoPath) {
      if (user.id) await userService.updateUserPhotoPath(user.id, photoPath);
    }

    localStorage.setItem("id", id || user?.id || "");
    localStorage.setItem("firstName", user?.firstName || "");
    localStorage.setItem("email", user?.email || "");
    localStorage.setItem("lastName", user?.lastName || "");
    localStorage.setItem("admissionDate", String(user?.admissionDate) || "");
    localStorage.setItem("creationDate", String(user?.creationDate) || "");
    localStorage.setItem("isActive", `${user.isActive}` || "");
    localStorage.setItem("sector", JSON.stringify(user?.sector) || "");
    localStorage.setItem("photoPath", photoPath || user.photoPath || "");
    localStorage.setItem("accessToken", accessToken || "");
    localStorage.setItem("isLogged", "true" || "");
    localStorage.setItem("role", JSON.stringify(user?.role || "") || "");

    const responseData = {
      accessToken,
      email,
      firstName: user?.firstName,
      photoPath: photoPath || user.photoPath,
      isLogged: true,
      id: id,
      lastName: user?.lastName,
      admissionDate: String(user?.admissionDate),
      creationDate: String(user?.creationDate),
      isActive: user?.isActive,
      sector: user?.sector,
      role: user?.role,
    };
    return responseData;
  }
};

export default signIn;
