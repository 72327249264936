import { Task } from "../entities/Task";
import { FirestoreService, ECollections as coll } from "../firebase";

export class TaskRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public async getAllInProject(projectId: string): Promise<Task[]> {
    const tasks = await this.firestore.getAllDocuments(
      `${coll.PROJECT}/${projectId}/${coll.TASK}`,
    );
    return tasks.map((task) => Task.fromJson(task.data, task.id));
  }

  public async create(projectId: string, task: Task): Promise<string> {
    const result = await this.firestore.setDocument(
      `${coll.PROJECT}/${projectId}/${coll.TASK}`,
      task.toJson(),
    );
    return result.id;
  }

  public async update(projectId: string, id: string, taskData) {
    taskData.updated_at = new Date();
    const result = await this.firestore.updateDocument(
      `${coll.PROJECT}/${projectId}/${coll.TASK}`,
      taskData,
      id,
    );

    return result;
  }
}
