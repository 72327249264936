import {
  Center,
  Divider,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import CustomButtom from "components/Form/Button";
import CustomCheckBox from "components/Form/CustomCheckBox";
import Dropdown from "components/Form/Select";
import InputWithLabel from "components/Form/input/InputWithLabel";
import SingleDatePicker from "components/SingleDatePicker";
import { IUser } from "interfaces/IUser";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SectorsService } from "services/SectorsService";
import { UserService } from "services/UserService";

interface IRegistrationDataProps {
  selectedUserId: string;
  user: IUser;
}

export const RegistrationData: React.FC<IRegistrationDataProps> = ({
  selectedUserId,
  user,
}) => {
  const userService = new UserService();
  const sectorsService = new SectorsService();

  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });
  const history = useHistory();
  const toast = useToast();
  const typeSalarys = ["Cargo de confiança", "Hora", "Fixo/Mês"];
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const hiring = ["PJ - Contratação empresa", "CLT - Pessoa física"];

  const [sectors, setSectors] = useState<any[]>([]);
  const [managers, setManagers] = useState([{} as IUser]);
  const [userSelected, setUserSelected] = useState<IUser>({
    id: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    firstName: "",
    lastName: "",
    email: "",
    sector: {
      id: "",
      name: "",
    },
    role: {
      id: "user",
      name: "Usuário",
    },
  });

  const formatCPF = useCallback((cpf) => {
    return cpf
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }, []);

  const formatRG = useCallback((rg) => {
    return rg
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1})$/, "$1-$2");
  }, []);

  const formatCNPJ = useCallback((cnpj) => {
    return cnpj
      .replace(/\D/g, "") // Remove caracteres não numéricos
      .replace(/(\d{2})(\d)/, "$1.$2") // Adiciona o primeiro ponto
      .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
      .replace(/(\d{3})(\d{4})/, "$1/$2") // Adiciona a barra
      .replace(/(\d{4})(\d{2})$/, "$1-$2"); // Adiciona o hífen
  }, []);

  const formatPhone = useCallback((phone) => {
    phone = phone.replace(/\D/g, "");

    if (phone.length > 10) {
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  }, []);

  const formatToBRL = (value) => {
    const numericValue = value.replace(/\D/g, "");

    const formattedValue = numericValue
      .replace(/(\d{2})$/, ",$1") // Adiciona a vírgula antes dos últimos dois dígitos
      .replace(/(?=(\d{3})+(\D))\B/g, "."); // Adiciona pontos a cada 3 dígitos

    return `R$ ${formattedValue}`;
  };

  const handleChangeMoney = useCallback(
    (event) => {
      const inputValue = event;
      const formattedInput = formatToBRL(inputValue);

      setUserSelected({
        ...userSelected,
        salary: !inputValue ? "0" : formattedInput.split("R$ ")[1],
      });
    },
    [userSelected],
  );

  const formatAgency = useCallback((agency) => {
    const formattedAgency = agency.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    return formattedAgency.length === 4
      ? formattedAgency
      : formattedAgency.slice(0, 4) + "-" + formattedAgency.slice(4);
  }, []);

  const formatAccount = useCallback((account) => {
    const formattedAccount = account.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const length = formattedAccount.length;

    if (length < 2) return formattedAccount;

    const accountNumber = formattedAccount.slice(0, length - 1);
    const checkDigit = formattedAccount.slice(length - 1);

    return accountNumber + "-" + checkDigit;
  }, []);

  const handleChangeUser = useCallback(
    (event) => {
      if (event.target.name === "cpf") {
        setUserSelected({
          ...userSelected,
          cpf: formatCPF(event.target.value),
        });
        return;
      }
      if (event.target.name === "cnpj") {
        setUserSelected({
          ...userSelected,
          cnpj: formatCNPJ(event.target.value),
        });
        return;
      }

      if (event.target.name === "rg") {
        setUserSelected({
          ...userSelected,
          rg: formatRG(event.target.value),
        });
        return;
      }
      if (event.target.name === "phone_number") {
        setUserSelected({
          ...userSelected,
          phoneNumber: formatPhone(event.target.value),
        });
        return;
      }

      if (event.target.name === "phone_number_optional") {
        setUserSelected({
          ...userSelected,
          phoneNumberOptional: formatPhone(event.target.value),
        });
        return;
      }

      if (event.target.name === "salary") {
        handleChangeMoney(event.target.value);
        return;
      }

      if (event.target.name === "cep") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          address: {
            cep: event.target.value,
            home_address: prevUser.address?.home_address ?? "",
            city: prevUser.address?.city ?? "",
            neighborhood: prevUser.address?.neighborhood ?? "",
            municipality: prevUser.address?.municipality ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "city") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          address: {
            city: event.target.value,
            cep: prevUser.address?.cep ?? "",
            home_address: prevUser.address?.home_address ?? "",
            neighborhood: prevUser.address?.neighborhood ?? "",
            municipality: prevUser.address?.municipality ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "home_address") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          address: {
            city: prevUser.address?.city ?? "",
            cep: prevUser.address?.cep ?? "",
            home_address: event.target.value,
            neighborhood: prevUser.address?.neighborhood ?? "",
            municipality: prevUser.address?.municipality ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "municipality") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          address: {
            city: event.target.value,
            cep: prevUser.address?.cep ?? "",
            home_address: prevUser.address?.home_address ?? "",
            neighborhood: prevUser.address?.neighborhood ?? "",
            municipality: event.target.value,
          },
        }));
        return;
      }

      if (event.target.name === "neighborhood") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          address: {
            city: prevUser.address?.city ?? "",
            cep: prevUser.address?.cep ?? "",
            home_address: prevUser.address?.home_address ?? "",
            neighborhood: event.target.value,
            municipality: prevUser.address?.municipality ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "bank") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          account: {
            agency: prevUser?.account?.agency ?? "",
            bank: event.target.value,
            account: prevUser?.account?.account ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "agency") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          account: {
            agency: formatAgency(event.target.value),
            bank: prevUser?.account?.bank ?? "",
            account: prevUser?.account?.account ?? "",
          },
        }));
        return;
      }

      if (event.target.name === "account") {
        setUserSelected((prevUser) => ({
          ...prevUser,
          account: {
            agency: prevUser?.account?.agency ?? "",
            bank: prevUser?.account?.bank ?? "",
            account: formatAccount(event.target.value),
          },
        }));
        return;
      }

      setUserSelected({
        ...userSelected,
        [event.target.name]: event.target.value,
      });
    },
    [
      userSelected,
      formatCPF,
      formatCNPJ,
      formatRG,
      formatPhone,
      handleChangeMoney,
      formatAccount,
      formatAgency,
    ],
  );

  const handleGetSector = useCallback(async () => {
    const response = await sectorsService.getAllSectors();

    if (response.length > 0) {
      setSectors([
        { id: "Selecione uma opção", name: "Selecione uma opção" } as any,
        ...response.filter((s) => s.name),
      ]);
    } else {
      setSectors([]);
      toast({
        title: "Ocorreu um erro ao buscar os setores!",
        description: "",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  const handleGetManagers = useCallback(async () => {
    const response = await userService.getAllUsersByRoles(["manager", "admin"]);

    if (response) {
      setManagers([
        { id: "Selecione uma opção", name: "Selecione uma opção" } as any,
        ...response.sort((a, b) => a.firstName.localeCompare(b.firstName)),
      ]);
    } else {
      setManagers([]);
      toast({
        title: "Ocorreu um erro ao buscar os gestores!",
        description: "",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  const handleGetAddressByCep = useCallback(
    async (cep) => {
      setLoadingAddress(true);
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);

        const address = await response.json();

        setUserSelected({
          ...userSelected,
          address: {
            ...userSelected.address,
            cep: cep,
            home_address: address.logradouro ?? "",
            city: address.localidade ?? "",
            neighborhood: address.bairro ?? "",
            municipality: address.uf ?? "",
          },
        });

        setLoadingAddress(false);
      } catch (err) {
        toast({
          title: "Ocorreu um erro ao buscar o endereço!",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
      setLoadingAddress(false);
    },
    [toast, userSelected],
  );

  const handleFieldsIsCorrect = useCallback(
    (object: IUser) => {
      const camposVazios: string[] = [];
      const translateKeys = [
        { id: "firstName", name: "Primeiro nome" },
        { id: "lastName", name: "Último nome" },
        { id: "email", name: "Email" },
        { id: "role", name: "Permissões" },
        { id: "sector", name: "Setor" },
      ];

      for (const chave in object) {
        if (Object.prototype.hasOwnProperty.call(object, chave)) {
          const campo = object[chave];

          const isEmpty =
            campo === null ||
            campo === undefined ||
            campo === "" ||
            campo === "Selecione uma opção" ||
            (campo instanceof Date && isNaN(campo.getTime())) ||
            (typeof campo === "object" &&
              (campo.name === "Selecione uma opção" ||
                campo.name === "" ||
                campo.name === undefined ||
                campo.name === null));

          if (isEmpty) {
            const translatedKey = translateKeys.find(
              (key) => key.id === chave,
            )?.name;
            if (translatedKey) {
              camposVazios.push(translatedKey);
            }
          }
        }
      }

      if (camposVazios.length > 0) {
        const mensagem =
          camposVazios.length > 2
            ? `${camposVazios.slice(0, 2).join(", ")}...`
            : camposVazios.join(", ");

        toast({
          title: "Atenção!",
          description: `Alguns campos estão faltando: ${mensagem}`,
          status: "warning",
          duration: 6000,
          isClosable: true,
        });

        return false;
      }

      return true;
    },
    [toast],
  );

  const handleCreateOrUpdateUser = useCallback(
    async (user: IUser) => {
      const checkFieldsResult = handleFieldsIsCorrect({ ...user });
      if (checkFieldsResult) {
        user.isActive = true;
        setLoadingUser(true);
        if (selectedUserId !== "0") {
          const result = await userService.updateUser(selectedUserId, {
            ...user,
          });

          if (!result) {
            toast({
              title: "Ocorreu um erro.",
              description: "Erro ao atualizar o usuário",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            setLoadingUser(false);
            return;
          }

          toast({
            title: "Dados atualizados com sucesso.",
            description: "Usuário foi atualizado com sucesso!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          if (user.firstName !== "") {
            const response = await userService.createUser({
              ...user,
            });

            if (response) {
              history.push(`/users/create/${response}`);

              toast({
                title: "Dados inseridos com sucesso.",
                description: "Usuário criado com sucesso!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              return;
            }
            toast({
              title: "Ocorreu um erro.",
              description: "Erro ao criar um usuário",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }

        setLoadingUser(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFieldsIsCorrect, history, selectedUserId, toast],
  );

  useEffect(() => {
    handleGetManagers();
    handleGetSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.firstName) {
      setUserSelected(user);
    }
  }, [user]);

  return (
    <>
      {loadingUser ? (
        <>
          <Flex w="100%" h="50vh" align={"center"} justify={"center"}>
            <Spinner />
          </Flex>
        </>
      ) : (
        <>
          <Flex
            flexDir={["column", "column", "row"]}
            overflow={"auto"}
            minH="calc(100vh - 370px)"
            maxH="calc(100vh - 370px)"
            gap="12"
            pr="2"
            pt="2"
          >
            <Flex flexDir={"column"} flex="1" justify={"space-between"} gap="2">
              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <InputWithLabel
                  label="Nome"
                  name={"firstName"}
                  value={userSelected.firstName}
                  onChange={(e) => handleChangeUser(e)}
                  required
                />
                <InputWithLabel
                  label="Sobrenome"
                  name={"lastName"}
                  value={userSelected.lastName}
                  onChange={(e) => handleChangeUser(e)}
                  required
                />
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <InputWithLabel
                  label="CPF"
                  maxLength={14}
                  name={"cpf"}
                  value={userSelected.cpf}
                  onChange={(e) => handleChangeUser(e)}
                />
                <InputWithLabel
                  label="RG"
                  maxLength={12}
                  name={"rg"}
                  value={userSelected.rg}
                  onChange={(e) => handleChangeUser(e)}
                />
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <SingleDatePicker
                  minW={isMobile ? "calc(100vw - 100px)" : "13vw"}
                  handleChange={(e) => {
                    setUserSelected({
                      ...userSelected,
                      bornDate: new Date(e.day),
                    });
                  }}
                  label="Data de nascimento"
                  payload={{ day: userSelected?.bornDate }}
                />

                <InputWithLabel
                  label="Telefone"
                  name={"phoneNumber"}
                  value={userSelected.phoneNumber}
                  onChange={(e) => handleChangeUser(e)}
                  maxWid={isMobile ? "100%" : "11vw"}
                />

                <InputWithLabel
                  label="Telefone recado"
                  name={"phoneNumberOptional"}
                  value={userSelected.phoneNumberOptional}
                  onChange={(e) => handleChangeUser(e)}
                  maxWid={isMobile ? "100%" : "11vw"}
                />
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <InputWithLabel
                  label="Email pessoal"
                  name={"personalEmail"}
                  value={userSelected.personalEmail}
                  onChange={(e) => handleChangeUser(e)}
                />

                <InputWithLabel
                  label="Email HVAR"
                  name={"email"}
                  value={userSelected.email}
                  onChange={(e) => handleChangeUser(e)}
                  required
                />
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <Dropdown
                  minW={isMobile ? "100%" : "50%"}
                  fontSize={["sm"]}
                  label="Lider"
                  name="customer"
                  onChange={(e: any) => {
                    const manager = managers.find(
                      (item) => item?.id === e.target.value,
                    );

                    if (manager) {
                      setUserSelected({
                        ...userSelected,
                        manager: {
                          id: manager?.id ?? "",
                          name: `${manager.firstName} ${manager.lastName}`,
                        },
                      });
                    }
                  }}
                >
                  {managers?.find(
                    (item) => item?.id === userSelected?.manager?.id,
                  ) && (
                    <option value={userSelected?.manager?.id} selected>
                      {
                        managers?.find(
                          (item) => item?.id === userSelected?.manager?.id,
                        )?.firstName
                      }
                      {
                        managers?.find(
                          (item) => item?.id === userSelected?.manager?.id,
                        )?.lastName
                      }
                    </option>
                  )}
                  {managers?.map((item, key: number | string) => {
                    if (
                      item?.id !== userSelected?.manager?.id &&
                      item.firstName
                    )
                      return (
                        <option key={key} value={item?.id}>
                          {item.firstName} {item.lastName}
                        </option>
                      );
                  })}
                </Dropdown>

                <Dropdown
                  minW={isMobile ? "100%" : "50%"}
                  fontSize={["sm"]}
                  label="Torres"
                  name="sector"
                  required
                  onChange={(e: any) => {
                    const sector = sectors.find(
                      (item) => item?.id === e.target.value,
                    );

                    if (sector) {
                      setUserSelected({
                        ...userSelected,
                        sector: {
                          id: sector?.id,
                          name: sector.name,
                        },
                      });
                    }
                  }}
                >
                  {sectors?.find(
                    (item) => item?.id === userSelected.sector?.id,
                  ) && (
                    <option value={userSelected.sector.name} selected>
                      {
                        sectors?.find(
                          (item) => item?.id === userSelected.sector?.id,
                        )?.name
                      }
                    </option>
                  )}
                  {sectors.map((item, key: number | string) => {
                    if (item?.id !== userSelected.sector?.id)
                      return (
                        <option key={key} value={item?.id}>
                          {item.name}
                        </option>
                      );
                  })}
                </Dropdown>
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <SingleDatePicker
                  positionCalendar="right"
                  minW={isMobile ? "calc(100vw - 80px)" : "9vw"}
                  handleChange={(e) => {
                    setUserSelected({
                      ...userSelected,
                      startDate: new Date(e.day),
                    });
                  }}
                  label="Data de início"
                  payload={{ day: userSelected?.startDate }}
                />

                <InputWithLabel
                  label="Salario"
                  name={"salary"}
                  value={userSelected.salary}
                  onChange={(e) => handleChangeUser(e)}
                />

                <Dropdown
                  minW={isMobile ? "100%" : "40%"}
                  fontSize={["sm"]}
                  label="Tipo do salário"
                  name="salaryType"
                  onChange={(e: any) => {
                    handleChangeUser(e);
                  }}
                >
                  <option value="">Selecione uma opção</option>

                  {typeSalarys.map((item, key: number | string) => {
                    if (item === userSelected.salaryType) {
                      return (
                        <option key={key} value={item} selected>
                          {item}
                        </option>
                      );
                    }

                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Dropdown>
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <InputWithLabel
                  label="Cargo"
                  name={"jobRole"}
                  value={userSelected.jobRole}
                  onChange={(e) => handleChangeUser(e)}

                  // verificar a como vamos trocar role do usuario
                />

                <Dropdown
                  minW={isMobile ? "100%" : "50%"}
                  fontSize={["sm"]}
                  label="Tipo de contratação"
                  name="hiringRegime"
                  onChange={(e: any) => {
                    handleChangeUser(e);
                  }}
                >
                  <option value="">Selecione uma opção</option>

                  {hiring.map((item, key: number | string) => {
                    if (item === userSelected.hiringRegime) {
                      return (
                        <option key={key} value={item} selected>
                          {item}
                        </option>
                      );
                    }
                    return (
                      <option key={key} value={item} selected>
                        {item}
                      </option>
                    );
                  })}
                </Dropdown>
              </Flex>

              <Flex gap="5" flexDir={["column", "column", "row"]}>
                <InputWithLabel
                  label="Benefício PJ - HVAR"
                  name={"benefit"}
                  value={userSelected.benefit}
                  onChange={(e) => handleChangeUser(e)}
                  maxWid="14vw"
                />

                <SingleDatePicker
                  positionCalendar="right"
                  minW={isMobile ? "calc(100vw - 80px)" : "9vw"}
                  handleChange={(e) => {
                    setUserSelected({
                      ...userSelected,
                      benefitStartAt: new Date(e.day),
                    });
                  }}
                  label="Data início benefício"
                  payload={{ day: userSelected?.benefitStartAt }}
                />
              </Flex>

              <CustomCheckBox
                minW="140px"
                isChecked={userSelected?.equipmentNeeded}
                name="equipmentNeeded"
                onChange={(e) => {
                  setUserSelected({
                    ...userSelected,
                    [e.target.name]: e.target.checked,
                  });
                }}
              >
                <Text fontWeight="normal" fontSize={["sm"]}>
                  Necessita de Notebook
                </Text>
              </CustomCheckBox>
            </Flex>

            {!isMobile && (
              <Center>
                <Divider orientation="vertical" color="grayBg" />
              </Center>
            )}

            <Flex flexDir={"column"} flex="1" justify={"space-between"} gap="2">
              <Flex flexDir={"column"} h="100%" gap="3">
                <Flex gap="5" align={"center"}>
                  <InputWithLabel
                    label="CNPJ"
                    name={"cnpj"}
                    maxLength={18}
                    value={userSelected.cnpj}
                    onChange={(e) => handleChangeUser(e)}
                  />
                  <CustomCheckBox
                    minW="140px"
                    isChecked={userSelected?.withHoldTax}
                    name="withHoldTax"
                    onChange={(e) => {
                      setUserSelected({
                        ...userSelected,
                        [e.target.name]: e.target.checked,
                      });
                    }}
                  >
                    <Text fontWeight="normal" fontSize={["sm"]}>
                      Reter imposto
                    </Text>
                  </CustomCheckBox>
                </Flex>

                <Flex gap="5" flexDir={["column", "column", "row"]}>
                  <InputWithLabel
                    label="Cep"
                    name={"cep"}
                    value={userSelected?.address?.cep}
                    onChange={(e) => handleChangeUser(e)}
                    onBlur={(e) => {
                      handleGetAddressByCep(e.target.value);
                    }}
                    maxWid="10vw"
                  />
                  <InputWithLabel
                    label="Endereço"
                    name={"home_address"}
                    disabled={loadingAddress}
                    value={userSelected?.address?.home_address}
                    onChange={(e) => handleChangeUser(e)}
                  />
                </Flex>

                <Flex gap="5" flexDir={["column", "column", "row"]}>
                  <InputWithLabel
                    label="Cidade"
                    name={"city"}
                    disabled={loadingAddress}
                    value={userSelected?.address?.city}
                    onChange={(e) => handleChangeUser(e)}
                  />
                  <InputWithLabel
                    label="Bairro"
                    name={"neighborhood"}
                    disabled={loadingAddress}
                    value={userSelected?.address?.neighborhood}
                    onChange={(e) => handleChangeUser(e)}
                  />
                </Flex>

                <InputWithLabel
                  label="Cidade/municipio CNPJ"
                  name={"municipality"}
                  disabled={loadingAddress}
                  value={userSelected?.address?.municipality}
                  onChange={(e) => handleChangeUser(e)}
                />

                <Flex flexDir={"column"} marginY={"6"} gap="4">
                  <Text color="green1" fontSize={"xl"}>
                    Dados Bancários
                  </Text>

                  <Flex gap="5" flexDir={["column", "column", "row"]}>
                    <InputWithLabel
                      label="Banco"
                      name={"bank"}
                      value={userSelected?.account?.bank}
                      onChange={(e) => handleChangeUser(e)}
                    />
                    <InputWithLabel
                      label="Agência"
                      name={"agency"}
                      value={userSelected?.account?.agency}
                      onChange={(e) => handleChangeUser(e)}
                    />
                    <InputWithLabel
                      label="Conta"
                      name={"account"}
                      value={userSelected?.account?.account}
                      onChange={(e) => handleChangeUser(e)}
                    />
                  </Flex>

                  <Text color="blue" fontSize={"xs"}>
                    * Os dados bancários preenchidos acima precisam ser de uma
                    conta empresa (pessoa jurídica).
                  </Text>
                </Flex>

                <Flex align={"center"} justify={"flex-end"}>
                  <CustomButtom
                    primaryText="Cancelar"
                    py="6"
                    px="8"
                    onClick={() => {
                      history.push("/users");
                    }}
                  />
                  <CustomButtom
                    primaryText="Salvar dados do usuário"
                    bg="orange2"
                    color="white"
                    isLoading={loadingUser}
                    _hover={{
                      opacity: 0.8,
                    }}
                    onClick={async () => {
                      await handleCreateOrUpdateUser(userSelected);
                    }}
                    py="6"
                    px="10"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
