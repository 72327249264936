import {
  Flex,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Avatar,
  Text,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useMenu } from "modules/menu/hooks";
import { useRef } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../modules/auth/hooks";
import { checkPermissoion as checkPermission } from "helpers/checkPermission";
import { menus } from "./item";
import { useFilters } from "modules/filters/hooks";

const Menu: React.FC = () => {
  const { handleSetIsVisible, isVisible, handleSetTitle } = useMenu();
  const btnRef = useRef<any>();
  const link = useHistory();
  const { data } = useAuth();
  const nameSize = useBreakpointValue({ base: true, md: false, lg: false });
  const { data: searchFilters, handleSetPayload } = useFilters();

  return (
    <Flex>
      <Drawer
        isOpen={isVisible}
        placement="left"
        onClose={() => handleSetIsVisible(!isVisible)}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="white" color="black">
          <DrawerHeader bg="green1" color="white">
            <Flex align={"center"}>
              <Box>
                <Avatar
                  name={data?.firstName || ""}
                  src={data?.photoPath || ""}
                  size="md"
                  mr="3"
                />
              </Box>

              <Flex flexDir={"column"} mr={5}>
                <Tooltip
                  label={data?.firstName}
                  aria-label="A tooltip"
                  bg="black"
                  placement="top"
                >
                  <Text isTruncated maxW="200px" fontSize={"sm"}>
                    {nameSize ? (
                      <>
                        <>{data?.firstName}</>
                      </>
                    ) : (
                      <>
                        {data?.firstName} {data?.lastName}
                      </>
                    )}
                  </Text>
                </Tooltip>

                <Tooltip
                  label={data?.email}
                  aria-label="A tooltip"
                  bg="black"
                  placement="top"
                >
                  <Text fontSize={"sm"} isTruncated maxW="200px">
                    {data?.email}
                  </Text>
                </Tooltip>
              </Flex>
              <DrawerCloseButton size={"xl"} color="white" mt="4" />
            </Flex>
          </DrawerHeader>

          <DrawerBody p="0" m="0">
            <Accordion allowToggle defaultIndex={[0]}>
              {menus.map((menu, index) => {
                if (
                  checkPermission({
                    permission: menu.role,
                    userRole: data?.role?.id,
                  })
                ) {
                  return (
                    <>
                      <AccordionItem
                        key={`${menu.name}-${index}`}
                        fontSize={"sm"}
                        onClick={() => menu.onClick && menu.onClick()}
                      >
                        <h2>
                          <AccordionButton
                            borderBottom={"1px solid"}
                            borderColor={"grayLight"}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              {menu.name}
                            </Box>

                            {menu?.subItems && menu?.subItems?.length > 0 && (
                              <AccordionIcon />
                            )}
                          </AccordionButton>
                        </h2>
                        {menu?.subItems && menu?.subItems?.length > 0 && (
                          <>
                            {menu?.subItems?.map((subItem, key) => {
                              if (subItem)
                                if (
                                  checkPermission({
                                    permission: subItem.role,
                                    userRole: data.role.id,
                                  })
                                ) {
                                  return (
                                    <AccordionPanel
                                      p="0"
                                      m="0"
                                      key={`${subItem.link}-${key}`}
                                      bg={
                                        link.location.pathname.includes(
                                          subItem.link,
                                        )
                                          ? "grayLight"
                                          : "grayBg"
                                      }
                                    >
                                      <ChakraLink
                                        as={ReactRouterLink}
                                        to={subItem.link}
                                        onClick={() => {
                                          handleSetTitle(subItem.titlePage);
                                          handleSetIsVisible(!isVisible);
                                          handleSetPayload({
                                            ...searchFilters,
                                            clientFilter: "",
                                            taskFilter: "",
                                            userFilter: "",
                                            projectFilter: "",
                                          });
                                        }}
                                      >
                                        <Flex
                                          cursor="pointer"
                                          p="3"
                                          borderBottom={"1px solid"}
                                          borderColor={"grayLight"}
                                        >
                                          <Text>{subItem.name}</Text>
                                        </Flex>
                                      </ChakraLink>
                                    </AccordionPanel>
                                  );
                                }
                            })}
                          </>
                        )}
                      </AccordionItem>
                    </>
                  );
                }
              })}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Menu;
