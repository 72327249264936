import DatePicker from "react-multi-date-picker";

import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Icon from "react-multi-date-picker/components/icon";
import { useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";
import InputWithLabel from "components/Form/input/InputWithLabel";
import locale from "components/MultDatePicker/utils";

type SingleDatePickerProps = {
  handleChange?: (payload: any) => void;
  payload?: any;
  maxW?: string;
  minW?: string;
  positionCalendar?: string;
  label?: string;
  [key: string]: any;
};

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  handleChange,
  payload,
  maxW,
  minW,
  positionCalendar,
  label,
  ...rest
}) => {
  const [value, setValue] = useState<any>(
    payload?.day ? payload?.day : new Date(),
  );
  const datePickerRef = useRef<any>();

  const handleTakeValues = async (value: any) => {
    const mesesThatDosentHas31Days = [
      {
        month: "02",
        maxDay: 28,
      },
      {
        month: "04",
        maxDay: 30,
      },
      {
        month: "06",
        maxDay: 30,
      },
      {
        month: "09",
        maxDay: 30,
      },
      {
        month: 11,
        maxDay: 30,
      },
    ];

    let realInitialMonth;
    let realInitialDay;

    const initDate = `${value?.year}/${
      value?.month?.number < 10
        ? `0${value?.month?.number}`
        : value?.month?.number
    }/${value?.day < 10 ? `0${value?.day}` : value?.day}`;

    mesesThatDosentHas31Days.map((item) => {
      if (item.month == initDate.split("/")[1]) {
        realInitialMonth = item.month;

        if (Number(initDate.split("/")[2]) > item.maxDay)
          realInitialDay = item.maxDay;
      }
    });

    const initValueFormated = `${initDate.split("/")[0]}/${
      realInitialMonth ?? initDate.split("/")[1]
    }/${realInitialDay ?? initDate.split("/")[2]}`;

    handleChange &&
      handleChange({
        ...payload,
        day: new Date(initValueFormated),
      });
  };

  return (
    <Flex>
      <DatePicker
        ref={datePickerRef}
        className="green"
        format="DD/MM/YYYY"
        calendarPosition={positionCalendar}
        // eslint-disable-next-line react/jsx-key
        plugins={[<DatePanel />]}
        mapDays={({ date }) => {
          const props: any = {};
          const isWeekend = [0, 6].includes(date.weekDay.index);

          if (isWeekend) props.className = "highlight highlight-red";

          return props;
        }}
        render={(value: any, openCalendar: any) => {
          const firstValue = value;

          return (
            <Flex
              align={"center"}
              justify={"center"}
              gap="5px"
              borderBottom={"1px solid"}
              borderColor={"yellow"}
              color="black"
              // p="4px 8px"
            >
              <InputWithLabel
                onClick={openCalendar}
                defaultValue={firstValue ?? ""}
                placeholder="Data inicial"
                maxLength={10}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    handleTakeValues(value);
                  }
                }}
                maxW={maxW}
                minW={minW}
                border="none"
                label={label}
                {...rest}
              />

              <Icon
                onClick={() => {
                  if (datePickerRef.current.isOpen) {
                    datePickerRef.current.closeCalendar();
                  } else {
                    datePickerRef.current.openCalendar();
                  }
                }}
              />
            </Flex>
          );
        }}
        locale={locale}
        numberOfMonths={1}
        value={value}
        onChange={(date: any) => {
          setValue(date?.length > 0 ? date[1] : date?.isValid ? date : "");
          handleTakeValues(
            date?.length > 0 ? date[1] : date?.isValid ? date : "",
          );
        }}
      ></DatePicker>
    </Flex>
  );
};
export default SingleDatePicker;
