import React, { useEffect, useRef, useState, useCallback } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Img } from "@chakra-ui/react";
import "./DatePicker.css";
import InputWithLabel from "components/Form/input/InputWithLabel";
import Icon from "react-multi-date-picker/components/icon";
import leftArrow from "assets/img/leftArrow.svg";
import rightArrow from "assets/img/rightArrow.svg";
import locale from "./utils";

interface DateInputProps {
  onChange: (date?: Date) => void;
  value?: Date;
  label?: string;
  maxW?: string;
  minW?: string;
  [key: string]: any;
}

const DateInput: React.FC<DateInputProps> = ({
  onChange,
  value,
  label,
  maxW,
  minW,
  ...rest
}) => {
  const [selected, setSelected] = useState(value);
  const datePickerRef = useRef<any>();

  // useCallback para memorizar handleChange
  const handleChange = useCallback(
    (date: Date) => {
      setSelected(date);
      onChange(date);
    },
    [onChange],
  );

  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  useEffect(() => {
    if (value) setSelected(new Date(value));
  }, [value]);

  return (
    <Flex>
      <DatePicker
        popperPlacement="top-end"
        ref={datePickerRef}
        selected={selected}
        locale={locale}
        calendarContainer={MyContainer}
        onChange={(e) => {
          e == null ? handleChange(new Date()) : handleChange(e);
        }}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => {
          return (
            <Flex m="10px" justify={"space-between"} align={"center"}>
              <span>
                {monthDate.toLocaleString("default", { month: "long" })} de{" "}
                {monthDate.getFullYear()}
              </span>
              <Flex>
                <button
                  onClick={decreaseMonth}
                  style={{ marginRight: 3, padding: "5px" }}
                >
                  <Img src={leftArrow} />
                </button>

                <button
                  onClick={increaseMonth}
                  style={{ marginLeft: 3, padding: "5px" }}
                >
                  <Img src={rightArrow} />
                </button>
              </Flex>
            </Flex>
          );
        }}
        customInput={
          <Flex align={"center"} justify={"center"} gap="5px" color="black">
            <InputWithLabel
              defaultValue={value}
              maxLength={10}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  onChange(value);
                }
              }}
              maxW={maxW}
              minW={minW}
              label={label}
              value={selected
                ?.toISOString()
                ?.split("T")[0]
                ?.split("-")
                ?.reverse()
                ?.join("/")}
              icon={
                <Icon
                  onClick={() => {
                    datePickerRef.current.setOpen(
                      !datePickerRef.current.isCalendarOpen(),
                    );
                  }}
                />
              }
              {...rest}
            />
          </Flex>
        }
      />
    </Flex>
  );
};

export default React.memo(DateInput);
