import React, { lazy } from "react";

import LayoutLogged from "layouts/Logged";
import IPropLayoutLogged from "layouts/Logged/types";

import IPropLayoutLoggedOut from "layouts/LoggedOut/types";
import LoggedOut from "layouts/LoggedOut";
import { ClientsList } from "pages/Clients/ClientsList";
import { ClientCreate } from "pages/Clients/ClientsCreate";
import { ListProjects } from "pages/Projects";
import { CreateProject } from "pages/Projects/SubPages";

import { CreateOrUpdateUser } from "pages/Users/subPages";
import { ListUsers } from "pages/Users/listUsers";
import { Faq } from "pages/Faq";

const Login = lazy(() => import("pages/Login"));

const TimeSheetNote = lazy(() => import("pages/TimeSheet/TimeSheetNote"));
const TimeSheetApproval = lazy(
  () => import("pages/TimeSheet/TimeSheetApproval"),
);

interface IProp {
  path: string;
  title: string;
  name?: string;
  component: React.FC;
  layout: React.FC<IPropLayoutLogged> | React.FC<IPropLayoutLoggedOut>;
  isPublic?: boolean;
  params?: string[];
  breadcrumbs?: boolean;
  role?: string[];
}

export const routes: IProp[] = [
  {
    path: "/",
    title: "HVAR Connect - Login",
    component: Login,
    isPublic: true,
    layout: LoggedOut,
    role: ["manager", "admin", "user"],
  },

  {
    path: "/timesheet/note",
    title: "HVAR Connect - Apontamento de horas",
    component: TimeSheetNote,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin", "user"],
  },
  {
    path: "/timesheet/approval",
    title: "HVAR Connect - Aprovação de horas",
    component: TimeSheetApproval,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin"],
  },
  {
    path: "/projects",
    title: "HVAR Connect - Projetos",
    component: ListProjects,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin"],
  },
  {
    path: "/clients",
    title: "HVAR Connect - Clientes",
    component: ClientsList,
    isPublic: false,
    layout: LayoutLogged,
    role: ["admin"],
  },

  {
    path: "/clients/create/:clientId",
    title: "Clientes - Criação de clientes",
    component: ClientCreate,
    isPublic: false,
    layout: LayoutLogged,
    role: ["admin"],
  },
  {
    path: "/clients/create",
    title: "Clientes - Criação",
    component: ClientCreate,

    isPublic: false,
    layout: LayoutLogged,
    role: ["admin"],
  },
  {
    path: "/projects/create/:id",
    title: "HVAR Connect - Criação de projetos",
    component: CreateProject,

    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin"],
  },
  {
    path: "/users",
    title: "HVAR Connect - Usuarios",
    component: ListUsers,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin"],
  },
  {
    path: "/faq",
    title: "HVAR Connect - Faq",
    component: Faq,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin", "usser"],
  },
  {
    path: "/users/create/:id",
    title: "HVAR Connect - Usuarios",
    component: CreateOrUpdateUser,
    isPublic: false,
    layout: LayoutLogged,
    role: ["manager", "admin"],
  },
];
