import { Sector } from "entities/Sector";
import { SectorRepository } from "repositories/SectorRepository";

export class SectorsService {
  private sectorsRepository = new SectorRepository();

  convertToInterface(document: Sector): Sector {
    return <Sector>{
      id: document.id,
      createdAt: document.createdAt,
      description: document.description,
      name: document.name,
      updatedAt: document.updatedAt,
    };
  }

  public async getAllSectors(): Promise<Sector[]> {
    const sectors = await this.sectorsRepository.getAll();

    return sectors.map((document) => this.convertToInterface(document));
  }
}
