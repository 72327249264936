import { handleLogout } from "../../modules/auth/actions";

export const menus = [
  {
    id: "1",
    name: "TimeSheet",
    link: "",
    role: ["User", "Manager", "Admin"],
    subItems: [
      {
        name: "Apontamento de horas",
        titlePage: "TimeSheet - Apontamento de horas",
        link: "/timesheet/note",
        role: ["Manager", "Admin", "User"],
      },
      {
        name: "Aprovação de horas",
        titlePage: "TimeSheet - Aprovação de horas",
        link: "/timesheet/approval",
        role: ["Manager", "Admin"],
      },
      {
        name: "Clientes",
        titlePage: "Clientes - Listagem",
        link: "/clients",
        role: ["Admin"],
      },
      {
        name: "Usuários",
        titlePage: "Usuários - Listagem de usuários",
        link: "/users",
        role: ["Admin", "Financeiro"],
      },
      {
        name: "Projetos",
        titlePage: "Projetos - Listagem de projetos",
        link: "/projects",
        role: ["Manager", "Admin"],
      },
      {
        name: "FAQ",
        titlePage: "FAQ",
        link: "/faq",
        role: ["Manager", "Admin"],
      },
    ],
  },

  {
    id: "0",
    name: "Sair do Web App",
    link: "/",
    role: ["User", "Manager", "Admin"],
    onClick: handleLogout,
  },
];
