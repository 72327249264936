import {
  Center,
  Divider,
  Flex,
  Grid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import InputWithLabel from "components/Form/input/InputWithLabel";
import { useCallback, useEffect, useState } from "react";

import pdfImg from "assets/img/pdfImg.png";
import Upload from "assets/img/upload.svg";
import { DocumentsService } from "services/DocumentsService";
import { UserService } from "services/UserService";
import { IDocumentData, IimageBlob } from "interfaces/IDocuments";
import { UploadButtonUser } from "../Components/uploadButtonUser";
import { IUser } from "interfaces/IUser";
import { UploadSquareButtonUser } from "../Components/uploadSquareButtonUser";
import { useParams } from "react-router-dom";
import ModalWrapper from "components/Modal";
import { ModalConfirmDelete } from "components/Modal/ModalConfirmDelete/confirmDeleteModal";

interface IDocumentsProps {
  user: IUser;
}

type DocType = "nf" | "pf_id" | "pj_id" | "other";

export const Documents: React.FC<IDocumentsProps> = ({ user }) => {
  const [openModalCofirmDelete, setOpenModalCofirmDelete] = useState(false);
  const [selectedDocumentToDelete, setSelectedDocumentToDelete] = useState<{
    userId?: string;
    docBucketId?: string;
    documentId?: string;
  }>({
    userId: "",
    docBucketId: "",
    documentId: "",
  });

  const handleSetDocumentToDelete = (
    userId?: string,
    docBucketId?: string,
    documentId?: string,
  ) => {
    setOpenModalCofirmDelete(true);

    setSelectedDocumentToDelete({
      userId,
      docBucketId,
      documentId,
    });
  };

  const documentService = new DocumentsService();
  const userService = new UserService();

  const [cnpjCard, setCnpjCard] = useState<Blob | null>(null);
  const [socialContract, setSocialContract] = useState<Blob | null>(null);
  const [residentialProof, setResidentialProof] = useState<Blob | null>(null);
  const [identityOrCNH, setIdentityOrCNH] = useState<Blob | null>(null);

  const [imageBloProposal, setImageBlobProposal] = useState<Blob | null>(null);
  const [imageBlobContract, setImageBlobContract] = useState<Blob | null>(null);
  const [documents, setDocuments] = useState<IDocumentData[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<IDocumentData[]>(
    [],
  );
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const toast = useToast();
  const { id } = useParams<{ id: string }>();

  const [documentData, setDocumentData] = useState<IDocumentData>({
    type: "other",
    id: "",
    uri: "",
    name: "",
    description: "",
    owner: {
      id: user?.id ?? "",
      name: `${user?.firstName} ${user?.lastName}` ?? "",
      email: user?.email ?? "",
      type: "user",
    },
    created_at: new Date(),
    updated_at: new Date(),
  });

  const handleOpenModalConfirm = () => {
    setOpenModalCofirmDelete((prev) => !prev);
  };

  const handleFilterDocuments = useCallback(
    (value: string) => {
      if (value) {
        const findedDoc = documents.filter((item) =>
          item?.name?.toLocaleLowerCase().includes(value),
        );

        setDocumentsFiltered(findedDoc);
        return;
      }
      setDocumentsFiltered(documents);
    },
    [documents],
  );

  const handleDeleteDocumentUser = useCallback(async (id: string) => {
    await documentService.deleteDocument(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDocs = useCallback(async () => {
    const response = await documentService.getDocsById(user?.id || id);

    if (response.length > 0) {
      setDocuments(response);
      setDocumentsFiltered(response);
      setLoadingDocs(false);
    } else {
      setLoadingDocs(false);
      toast({
        title: "Nenhum documento encontrado!",
        description: "",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }

    if (!response) {
      toast({
        title: "Ocorreu um erro!",
        description: "",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, toast, user?.id]);

  const handleDeleteDocInBucket = useCallback(async (userId, docName) => {
    await userService.deleteDocumentUser(
      `application/docs/user/${userId}/${docName}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pegar o id do documento

  const handleDeleteDocuments = useCallback(
    async (userId: string, docBucketId: string, documentId: string) => {
      setLoadingDocs(true);

      try {
        await handleDeleteDocInBucket(userId, docBucketId);
        await handleDeleteDocumentUser(documentId);

        await handleGetDocs();
        toast({
          title: "Dados atualizados com sucesso!",
          description: "Documento deletado!",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } catch (err) {
        toast({
          title: "Ocorreu um erro ao deletar o documento!",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
      setOpenModalCofirmDelete(false);
      setSelectedDocumentToDelete({
        userId: "",
        docBucketId: "",
        documentId: "",
      });
      setLoadingDocs(false);
    },
    [handleDeleteDocInBucket, handleDeleteDocumentUser, handleGetDocs, toast],
  );

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      await handleUploadDocument(documentData, file);
    }
  };

  const handleInsertImg = useCallback(
    async (id, image) => {
      try {
        return await userService.uploadDocumentUser(
          image,
          `application/docs/user/${id}/`,
        );
      } catch (err) {
        toast({
          title: "Ocorreu um erro ao inserir o documento!",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast],
  );

  const handleUploadDocument = useCallback(
    async (
      document: IDocumentData,
      blob: IimageBlob | null,
      title?: string,
      type?: DocType,
    ) => {
      setLoadingDocs(true);

      try {
        const responseBucket = await handleInsertImg(user?.id, blob);

        if (responseBucket) {
          const data: IDocumentData = {
            ...document,
            type: type ?? "other",
            created_at: new Date(),
            updated_at: new Date(),
            name: title ?? blob?.name ?? "",
            uri: responseBucket,
          };

          await documentService.createDocument(data);

          await handleGetDocs();

          toast({
            title: "Dados inseridos com sucesso!",
            description: "Documento criado.",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Ocorreu um erro ao inserir o documento!",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }

      setLoadingDocs(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetDocs, handleInsertImg, toast, user?.id],
  );

  useEffect(() => {
    handleFilterDocuments(searchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    handleGetDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocumentData({
      ...documentData,
      owner: {
        id: user?.id ?? "",
        name: `${user?.firstName} ${user?.lastName}` ?? "",
        email: user?.email ?? "",
        type: "user",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Flex
        w="100%"
        h="calc(100vh - 340px)"
        flexDir={["column", "column", "row"]}
        gap={6}
      >
        <Flex flex="0.7" flexDir={"column"} align={"center"}>
          <Text fontSize={"lg"} color="green1">
            Documentos Hvar Consultoria
          </Text>

          <Flex mt="6" flexDir={"column"} gap="6">
            <UploadButtonUser
              setImageBlob={setImageBlobProposal}
              imageBlob={imageBloProposal}
              Upload={
                documents?.find(
                  (item) => item.name === "Proposta Hvar consultoria",
                )
                  ? pdfImg
                  : Upload
              }
              label={
                documents?.find(
                  (item) => item.name === "Proposta Hvar consultoria",
                )?.name || "Proposta Hvar consultoria"
              }
              selectedDocument={documents?.find(
                (item) => item.name === "Proposta Hvar consultoria",
              )}
              handleSetDocumentToDelete={handleSetDocumentToDelete}
              loading={loadingDocs}
              handleUploadDocument={handleUploadDocument}
              documentData={documentData}
            />

            <UploadButtonUser
              setImageBlob={setImageBlobContract}
              imageBlob={imageBlobContract}
              Upload={
                documents?.find(
                  (item) => item.name === "Contrato Hvar consultoria",
                )
                  ? pdfImg
                  : Upload
              }
              label={
                documents?.find(
                  (item) => item.name === "Contrato Hvar consultoria",
                )?.name || "Contrato Hvar consultoria"
              }
              selectedDocument={documents?.find(
                (item) => item.name === "Contrato Hvar consultoria",
              )}
              loading={loadingDocs}
              handleUploadDocument={handleUploadDocument}
              documentData={documentData}
              handleSetDocumentToDelete={handleSetDocumentToDelete}
            />
          </Flex>
        </Flex>

        <Center>
          <Divider orientation="vertical" color="grayBg" />
        </Center>

        <Flex flex="1" flexDir={"column"} gap="6">
          <Flex justify={"space-between"} width={"100%"} gap="3">
            <Text fontSize={"lg"} color="green1">
              Documentos do consultor
            </Text>

            <label
              style={{
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Text fontSize={"sm"}>+ Adicionar novo</Text>
            </label>
          </Flex>

          <Flex>
            <InputWithLabel
              label="Pesquisar"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Flex>

          {loadingDocs ? (
            <Flex mt="20px" justify={"center"} h="100%">
              <Spinner size={"lg"} />
            </Flex>
          ) : (
            <Grid
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(4, 1fr)",
              ]}
              justifyItems={"center"}
              gap="6"
              pr="3"
              overflow={"auto"}
              maxH="calc(100vh - 500px)"
            >
              <>
                <UploadSquareButtonUser
                  setImageBlob={setCnpjCard}
                  imageBlob={cnpjCard}
                  Upload={
                    documents?.find((item) => item.name === "Cartão CNPJ")
                      ? pdfImg
                      : Upload
                  }
                  label={
                    documents?.find((item) => item.name === "Cartão CNPJ")
                      ?.name || "Cartão CNPJ"
                  }
                  selectedDocument={documents?.find(
                    (item) => item.name === "Cartão CNPJ",
                  )}
                  loading={loadingDocs}
                  handleUploadDocument={handleUploadDocument}
                  documentData={documentData}
                  handleSetDocumentToDelete={handleSetDocumentToDelete}
                />

                <UploadSquareButtonUser
                  setImageBlob={setSocialContract}
                  imageBlob={socialContract}
                  Upload={
                    documents?.find((item) => item.name === "Contrato social")
                      ? pdfImg
                      : Upload
                  }
                  label={
                    documents?.find((item) => item.name === "Contrato social")
                      ?.name || "Contrato social"
                  }
                  selectedDocument={documents?.find(
                    (item) => item.name === "Contrato social",
                  )}
                  loading={loadingDocs}
                  handleUploadDocument={handleUploadDocument}
                  documentData={documentData}
                  handleSetDocumentToDelete={handleSetDocumentToDelete}
                />
                <UploadSquareButtonUser
                  setImageBlob={setResidentialProof}
                  imageBlob={residentialProof}
                  Upload={
                    documents?.find(
                      (item) => item.name === "Comprovante residencial",
                    )
                      ? pdfImg
                      : Upload
                  }
                  label={
                    documents?.find(
                      (item) => item.name === "Comprovante residencial",
                    )?.name || "Comprovante residencial"
                  }
                  selectedDocument={documents?.find(
                    (item) => item.name === "Comprovante residencial",
                  )}
                  loading={loadingDocs}
                  handleUploadDocument={handleUploadDocument}
                  documentData={documentData}
                  handleSetDocumentToDelete={handleSetDocumentToDelete}
                />

                <UploadSquareButtonUser
                  setImageBlob={setIdentityOrCNH}
                  imageBlob={identityOrCNH}
                  Upload={
                    documents?.find((item) => item.name === "Identidade ou CNH")
                      ? pdfImg
                      : Upload
                  }
                  label={
                    documents?.find((item) => item.name === "Identidade ou CNH")
                      ?.name || "Identidade ou CNH"
                  }
                  selectedDocument={documents?.find(
                    (item) => item.name === "Identidade ou CNH",
                  )}
                  loading={loadingDocs}
                  handleUploadDocument={handleUploadDocument}
                  documentData={documentData}
                  handleSetDocumentToDelete={handleSetDocumentToDelete}
                />

                {documentsFiltered
                  ?.filter(
                    (item) =>
                      item.name !== "Contrato Hvar consultoria" &&
                      item.name !== "Proposta Hvar consultoria" &&
                      item.name !== "Cartão CNPJ" &&
                      item.name !== "Contrato social" &&
                      item.name !== "Comprovante residencial" &&
                      item.name !== "Identidade ou CNH",
                  )
                  .map((item, key) => {
                    return (
                      <>
                        <UploadSquareButtonUser
                          selectedDocument={item}
                          Upload={pdfImg}
                          handleSetDocumentToDelete={handleSetDocumentToDelete}
                          key={key}
                        />
                      </>
                    );
                  })}
              </>
            </Grid>
          )}
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={openModalCofirmDelete}
        onClose={handleOpenModalConfirm}
      >
        <ModalConfirmDelete
          handleClose={handleOpenModalConfirm}
          textModal={"O documento será excluído."}
          handleDelete={async () => {
            await handleDeleteDocuments(
              selectedDocumentToDelete.userId || "",
              selectedDocumentToDelete.docBucketId || "",
              selectedDocumentToDelete.documentId || "",
            );
          }}
        />
      </ModalWrapper>
    </>
  );
};
