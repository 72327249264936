import { UserRepository } from "repositories/UserRepository";
import { IUser } from "../interfaces/IUser";
import { User } from "entities/User";

export class UserService {
  private userRepository = new UserRepository();

  convertToInterface(user: IUser): IUser {
    return <User>{
      id: user.id,
      email: user.email,
      lastName: user.lastName,
      admissionDate: user.admissionDate,
      creationDate: user.creationDate,
      isActive: user.isActive,
      sector: user.sector,
      photoPath: user.photoPath,
      role: user.role,
      firstName: user.firstName,
      account: user.account,
      address: user.address,
      benefit: user.benefit,
      benefitStartAt: user.benefitStartAt,
      bornDate: user.bornDate,
      cnpj: user.cnpj,
      cpf: user.cpf,
      equipmentNeeded: user.equipmentNeeded,
      hiringRegime: user.hiringRegime,
      jobRole: user.jobRole,
      manager: user.manager,
      personalEmail: user.personalEmail,
      phoneNumber: user.phoneNumber,
      phoneNumberOptional: user.phoneNumberOptional,
      rg: user.rg,
      salary: user.salary,
      salaryType: user.salaryType,
      startDate: user.startDate,
      withHoldTax: user.withHoldTax,
    };
  }

  convertToEntity(user: IUser): User {
    return new User(
      user.id,
      user.firstName,
      user.lastName,
      user.email,
      user.createdAt,
      user.updatedAt,
      user.isActive,
      user.role,
      user.sector,
      user.admissionDate,
      user.bornDate,
      user.photoPath,
      user.phoneNumber,
      user.jobRole,
      user.manager,
      user.cpf,
      user.rg,
      user.startDate,
      user.phoneNumberOptional,
      user.personalEmail,
      user.withHoldTax,
      user.salary,
      user.salaryType,
      user.cnpj,
      user.hiringRegime,
      user.creationDate,
      user.account,
      user.address,
      user.benefit,
      user.benefitStartAt,
      user.equipmentNeeded,
    );
  }

  public async getUserByEmail(email: string): Promise<IUser | undefined> {
    const user = await this.userRepository.getFiltered({ email: email });
    if (user) return this.convertToInterface(user);
    return undefined;
  }

  public async getUserById(id: string): Promise<User> {
    const user = await this.userRepository.get(id);
    return user;
  }

  public async getAllUsersByRoles(roles: string[]): Promise<IUser[]> {
    const users = await this.userRepository.getUsersByRoles(roles);

    return users.map((user) => User.fromJson(user?.data, user?.id ?? ""));
  }

  public async updateUserPhotoPath(id: string, photoPath: string) {
    // TODO: melhorar formato de atualização do dado

    this.userRepository.update(id, { photoPath: photoPath } as User);
  }

  public async updateUser(id: string, user: IUser): Promise<IUser> {
    const userConverted = this.convertToEntity(user);

    const userUpdated = await this.userRepository.update(id, userConverted);

    return { ...userUpdated, id };
  }

  public async createUser(user: IUser): Promise<string> {
    const userConverted = this.convertToEntity(user);

    return this.userRepository.create(userConverted);
  }

  public async deleteDocumentUser(path: string): Promise<IUser> {
    return this.userRepository.deleteDocInBucket(path);
  }
  public async uploadDocumentUser(img: Blob, path: string): Promise<any> {
    return this.userRepository.uploadUser(img, path);
  }

  public async getAllUsersOrdered(): Promise<User[]> {
    const usersResult = await this.userRepository.getAll();

    return usersResult.sort((a, b) =>
      `${a?.firstName} ${a.lastName}`?.localeCompare(
        `${b?.firstName} ${b.lastName}`,
      ),
    );
  }

  public async getAll(): Promise<IUser[]> {
    const usersResult = await this.userRepository.getAll();

    return usersResult.sort((a, b) =>
      a?.firstName?.localeCompare(b?.firstName),
    );
  }
}
