import { useCallback, useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AppProviders from "./AppProviders";
import { BrowserRouter, useLocation } from "react-router-dom";
import Routes from "./routes";
import versionData from "./version.json";
import { handleLogout } from "modules/auth/actions";

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const verifyVersion = useCallback(() => {
    const storedVersion = localStorage.getItem("appVersion");
    const currentVersion = versionData.version;

    if (storedVersion !== currentVersion) {
      handleLogout();

      localStorage.setItem("appVersion", currentVersion);
    }
  }, []);

  useEffect(() => {
    verifyVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function App() {
  return (
    <ChakraProvider>
      <AppProviders>
        <BrowserRouter>
          <ScrollToTopOnMount />
          <Routes />
        </BrowserRouter>
      </AppProviders>
    </ChakraProvider>
  );
}

export default App;
