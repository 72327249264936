import { Avatar, Flex, Img, Spinner, Text, useToast } from "@chakra-ui/react";
import Dropdown from "components/Form/Select";

import { IUser } from "interfaces/IUser";
import HeaderInside from "layouts/HeaderInside";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserService } from "services/UserService";
import Pagination from "components/Pagination";
import acceptContract from "assets/img/acceptContract.svg";
import acceptDocuments from "assets/img/acceptDocuments.svg";
import acceptProposal from "assets/img/acceptProposal.svg";
import acceptRegister from "assets/img/acceptRegister.svg";

import warningContract from "assets/img/warningContract.svg";
import warningDocuments from "assets/img/warningDocuments.svg";
import warningProposal from "assets/img/warningProposal.svg";
import warningRegister from "assets/img/warningRegister.svg";
import { IDocumentData } from "interfaces/IDocuments";
import { DocumentsService } from "services/DocumentsService";
import { User } from "entities/User";
import { useFilters } from "modules/filters/hooks";
import InputWithLabel from "components/Form/input/InputWithLabel";

export const ListUsers: React.FC = () => {
  const { data, handleSetPayload } = useFilters();

  const [isFilteredByisActive, setIsFilteredByIsActive] = useState<
    boolean | undefined
  >(true);
  const documentService = new DocumentsService();
  const userService = new UserService();
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    totalPages: 1,
  });
  const [documents, setDocuments] = useState<IDocumentData[]>([]);

  const [allUsers, setAllUsers] = useState<User[]>([]);

  const [allUsersFiltered, setAllUsersFiltered] = useState<IUser[]>([]);

  const handleGetDocs = useCallback(async () => {
    const response: IDocumentData[] = await documentService.getAllDocuments();

    if (response.length > 0) {
      setDocuments(response);

      return;
    }

    toast({
      title: "Ocorreu um erro!",
      description: "Erro buscar os documentos",
      status: "error",
      duration: 4000,
      isClosable: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginate = useCallback(
    (data, page, pageSize) => {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const dataSliced = data.slice(startIndex, endIndex);

      const userWihData = dataSliced.map((user) => {
        const userDocuments = documents.filter(
          (doc) => doc.owner.id === user.id,
        );

        return {
          ...user,
          userDocuments,
        };
      });

      return userWihData;
    },
    [documents],
  );

  const handleGetUsers = useCallback(async () => {
    setLoading(true);

    const response = await userService.getAllUsersOrdered();

    if (response.length > 0) {
      const totalDocuments = response.length;
      const totalPages = Math.ceil(totalDocuments / 20);

      let ordenedValues = response
        ? response.filter((value) => value.firstName)
        : [];

      ordenedValues = ordenedValues.sort((a, b) => {
        return `${a.firstName} ${a.lastName}`
          .toLowerCase()
          .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase());
      });

      setCurrentPage({
        ...currentPage,
        totalPages: totalPages,
      });

      handleFilter(data.userFilter || "", ordenedValues, isFilteredByisActive);
      setAllUsers(ordenedValues);
      setLoading(false);
      return;
    }

    toast({
      title: "Ocorreu um erro!",
      description: "",
      status: "error",
      duration: 4000,
      isClosable: true,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginate, toast]);

  const handleFilter = async (
    searchValue: string,
    users: IUser[],
    active?: boolean,
  ) => {
    if (searchValue) {
      const findedUsers = users.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const matchesSearch = fullName.includes(
          searchValue.toLocaleLowerCase(),
        );

        if (typeof active === "boolean") {
          const matchesActive = active ? user.isActive : !user.isActive;
          return matchesSearch && matchesActive;
        }
        return matchesSearch;
      });

      const totalDocuments = findedUsers.length;
      const totalPages = Math.ceil(
        20 > totalDocuments ? 1 : totalDocuments / 20,
      );

      setCurrentPage({
        ...currentPage,
        totalPages: totalPages,
      });

      setAllUsersFiltered(paginate(findedUsers, 1, 20));
    } else {
      let ordenedValues = users
        ? users.filter((value) => {
            if (typeof active === "boolean") {
              const matchesActive = active ? value.isActive : !value.isActive;

              return matchesActive;
            }
            return value.firstName && value;
          })
        : [];

      ordenedValues = ordenedValues.sort((a, b) => {
        return `${a.firstName} ${a.lastName}`
          .toLowerCase()
          .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase());
      });

      const totalDocuments = ordenedValues.length;
      const totalPages = Math.ceil(totalDocuments / 20);

      if (data.userFilter === "") {
        setCurrentPage({
          ...currentPage,
          totalPages: totalPages,
        });
      }

      setAllUsersFiltered(paginate(ordenedValues, currentPage.page, 20));
    }
  };

  useEffect(() => {
    handleGetDocs();
  }, [handleGetDocs]);

  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFilter(data.userFilter || "", allUsers, isFilteredByisActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.userFilter]);

  useEffect(() => {
    handleFilter("", allUsers, isFilteredByisActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.page]);

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Flex align={"center"} justify={"center"} gap={3} cursor={"pointer"}>
            <Text
              fontSize={["md"]}
              cursor={"pointer"}
              onClick={() => {
                history.push("users/create/0");
              }}
            >
              {" "}
              + Adicionar novo
            </Text>
          </Flex>
        </Flex>
      </HeaderInside>

      <Flex p="5" flexDir={"column"}>
        <Flex
          align={"center"}
          justify={"space-between"}
          gap="3"
          flexDir={["column", "column", "row"]}
        >
          <Flex
            w="100%"
            align={"center"}
            justify={"center"}
            gap={5}
            pos="relative"
          >
            <InputWithLabel
              label="Pesquisar"
              value={data.userFilter || ""}
              onChange={(e) => {
                handleSetPayload({ ...data, userFilter: e.target.value });
              }}
            />
          </Flex>
          <Dropdown
            minW={["100%", "100%", "100%"]}
            maxW={["100%", "100%", "100%"]}
            fontSize={["sm"]}
          >
            <option value="">Ordenar por </option>
            <option value="order">Ordem Alfabética </option>
          </Dropdown>

          <Dropdown
            fontSize={["sm"]}
            onChange={(e: any) => {
              setIsFilteredByIsActive(
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
              handleFilter(
                data.userFilter || "",
                allUsers,
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
            }}
          >
            <option value="">Filtrar por status </option>
            <option value={"true"} selected>
              Ativados
            </option>
            <option value={"false"}>Desativado</option>
          </Dropdown>
        </Flex>

        <Flex
          flexDir={"column"}
          maxH={[
            "calc(100vh - 320px)",
            "calc(100vh - 340px)",
            "calc(100vh - 400px)",
          ]}
          pr="2"
          overflow={"auto"}
          mt="5"
          gap="3"
        >
          {loading ? (
            <Flex
              minH="calc(100vh - 400px)"
              w="100%"
              align={"center"}
              justify={"center"}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {allUsersFiltered?.map((item, index) => {
                const requiredDocs = [
                  "Cartão CNPJ",
                  "Contrato social",
                  "Comprovante residencial",
                  "Identidade ou CNH",
                ];

                const findContractDoc = item.userDocuments?.find(
                  (item) => item.name === "Contrato Hvar consultoria",
                );

                const findProposalDoc = item.userDocuments?.find(
                  (item) => item.name === "Proposta Hvar consultoria",
                );
                const findRegister = item.userDocuments?.find(
                  (item) => item.name === "Identidade ou CNH",
                );

                const hasRequiredDocs = requiredDocs.every((docName) =>
                  item.userDocuments?.some((doc) => doc.name === docName),
                );

                return (
                  <>
                    <Flex
                      key={index}
                      align="center"
                      justify="space-between"
                      gap="20"
                      bg="grayBg"
                      p="6"
                      minH="70px"
                      maxH="70px"
                      borderRadius="8px"
                      overflowY="hidden"
                      overflowX="auto"
                      transition="opacity 0.15s ease-in-out" // Adiciona a transição
                      _hover={{
                        opacity: 0.5,
                      }}
                      cursor="pointer"
                      onClick={() => {
                        history.push(`users/create/${item.id}`);
                      }}
                    >
                      <Flex align="center" justify="space-between" flex="1">
                        <Flex align={"center"} gap="2">
                          <Avatar
                            name={item.firstName || undefined}
                            src={item.photoPath || undefined}
                            size="md"
                            mr="3"
                          />

                          <Flex
                            flexDir="column"
                            minWidth="180px"
                            flexBasis="0"
                            flexGrow="1"
                            maxWidth="180px"
                          >
                            <Text fontSize="10px" color="grayLight">
                              Nome completo
                            </Text>
                            <Text fontSize="sm" isTruncated>
                              {item.firstName} {item.lastName}
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex
                          flexDir="column"
                          minWidth="150px"
                          flexBasis="0"
                          flexGrow="2"
                          maxWidth="290px"
                        >
                          <Text fontSize="10px" color="grayLight">
                            Email
                          </Text>
                          <Text fontSize="sm" isTruncated>
                            {item.email}
                          </Text>
                        </Flex>

                        <Flex
                          flexDir="column"
                          minWidth="80px"
                          flexBasis="0"
                          flexGrow="1"
                          maxWidth="80px"
                        >
                          <Text fontSize="10px" color="grayLight">
                            Torre
                          </Text>
                          <Text fontSize="sm" isTruncated>
                            {item?.sector?.name}
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex
                        flex="0.5"
                        align="center"
                        justify="space-evenly"
                        gap="3"
                      >
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findContractDoc ? acceptContract : warningContract
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findProposalDoc ? acceptProposal : warningProposal
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findRegister ? acceptRegister : warningRegister
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              hasRequiredDocs
                                ? acceptDocuments
                                : warningDocuments
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                );
              })}
            </>
          )}
        </Flex>

        <Pagination
          currentPage={currentPage.page}
          loading={loading}
          onPageChange={(e) => {
            setCurrentPage({ ...currentPage, page: e });
          }}
          totalPages={currentPage.totalPages}
        />
      </Flex>
    </>
  );
};
