import { extendTheme, CSSReset, GlobalStyle } from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  baseStyle: {
    _focus: { boxShadow: "none" },
  },
};
const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 0,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
  },
  colors: {
    red: "#da0d0d",
    black: "#4e4e4e",
    white: "#FFFFFF",
    grayLight: "#a5a5a5",
    gray: "#555b5c",
    grayBg: "#f2f2f2",
    blue: "#1c556f ",
    blueDark: "#292a6b ",
    yellow: "#fbb713",
    orangeDark: "#e15a29",
    orange: "#d36b2c",
    orange2: "#F58434",
    green1: "#79a23f",
    green2: "#598f5a",
    green3: "#598f40",
    yellowWithLessOpacity: "rgb(252, 186, 3, 0.2)",
    green1WithLessOpacity: "rgb(121, 162, 63, 0.2)",
  },
  fonts: {
    body: "Montserrat-Regular, sans-serif",
    heading: "Montserrat-Bold, sans-serif",
    thin: "Montserrat-Thin, sans-serif",
    italic: "Montserrat-Italic, sans-serif",
    boldItalic: "Montserrat-BoldItalic, sans-serif",
    light: "Montserrat-Light, sans-serif",
    lightItalic: "Montserrat-LightItalic, sans-serif",
    medium: "Montserrat-Medium, sans-serif",
    mediumItalic: "Montserrat-MediumItalic, sans-serif",
    semiBold: "Montserrat-SemiBold, sans-serif",
    semiBoldItalic: "Montserrat-SemiBoldItalic, sans-serif",
    extraBold: "Montserrat-ExtraBold, sans-serif",
    extraBoldItalic: "Montserrat-ExtraBoldItalic, sans-serif",
    black: "Montserrat-Black, sans-serif",
    blackItalic: "Montserrat-BlackItalic, sans-serif",
  },
});

export { theme, CSSReset, GlobalStyle };
